<template>
    <div class="content p-0">
        <div class="card">
            <ul class="nav nav-justified nav-tabs nav-tabs-highlight mb-0">
                <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" :class="activeTab == 1 ? 'nav-link active' : 'nav-link'">Verifikasi Upla <span class="badge badge-info">{{getVerifUpla}}</span></a></li>
                <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" :class="activeTab == 2 ? 'nav-link active' : 'nav-link'" class="nav-link">Permintaan Perubahan Data <span class="badge bg-teal">{{getRequestUpla}}</span></a></li>
            </ul>

            <div class="tab-content">
                <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane fade'" id="verUpla">
                    <div class="bg-white card-header">
                        <div class="row align-items-center">
                            <div class="col-md-4 col-lg-5">
                                <div class="form-row">
                                    <div class="col-lg-4"> <h5 class="card-title font-weight-semibold">Daftar Pasien</h5></div>
                                    
                                    <div class="col-lg-8" v-if="isshowTable('ar_reg_date')">
                                        <div class="form-group mb-0 d-flex">
                                            <date-range-picker
                                                ref="picker"
                                                :locale-data="datePickerConfig.locale"
                                                :autoApply="datePickerConfig.autoApply"
                                                v-model="dateRange"
                                                :opens="'right'"
                                                :ranges="datePickerConfig.ranges"
                                                @update="updateValues"
                                            >
                                                <template v-slot:input="picker">
                                                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                </template>
                                            </date-range-picker>
                                            <div class="input-group-append calendar-group">
                                                <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-8 col-lg-9">
                                <div class="row g-1">
                                    <div class="col-md-auto">
																			<div class="d-flex flex-column flex-lg-row flex-nowrap">
                                        <div class="mb-1 mb-lg-0 mr-lg-1">
                                            <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                                        </div>
																				<div class="">
																						<button @click="openFilter = true" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Atur Kolom" v-b-tooltip.hover ><i class="icon-table2"></i></button>
																				</div>
																			</div>
                                    </div>
																		<div class="col-md">
																			<div class="row g-1">
																				<div class="col-md-6 col-lg-3" v-if="isshowTable('mpo_name')">
																						<b-form-group class="mb-0">
																								<v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
																						</b-form-group>
																				</div>
		
																				<div class="col-md-6 col-lg-3">
																						<b-form-group class="mb-0">
																								<v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.verif_status" :options="Config.mr.configStatusUpla" label="text" :reduce="v=>v.value"></v-select>
																						</b-form-group>
																				</div>
		
																				<div class="col-md-6 col-lg-4">
																						<b-form-group class="mb-0">
																								<v-select placeholder=" -- Pilih Cara Bayar -- " @input="doFill" v-model="filter.status_jaminan" :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
																						</b-form-group>
																				</div>
																				<div class="col-md-6 col-lg-4" v-if="filter.status_jaminan == 2">
																						<b-form-group class="mb-0">
																								<v-select placeholder=" -- Pilih Asuransi -- " @input="doFill" v-model="filter.tipe_jaminan" :options="mCarabayar" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
																						</b-form-group>
																				</div>
																			</div>
																		</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-3">
                                <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                    <input class="form-control"
                                        v-model="filter.search"
                                        @input="doSearch"
                                        placeholder="Ketik Nama/No. RM Pasien"
                                    />
                                    <div class="form-control-feedback">
                                        <i class="icon-search4 text-indigo"></i>
                                    </div>
                                        
                                    <b-button
                                        class="ml-1 d-inline-flex align-items-center"
                                        variant="outline-success"
                                        id="resetBtn"
                                        @click="doResetData()"
                                    >Reset</b-button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive sticky-table">
                        <table class="table table-bordered table-striped table-hover table-sm patient-table dashboard-table">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th v-if="isshowTable('ar_reg_code')">No. Kedatangan</th>
                                    <th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
                                    <th v-if="isshowTable('ar_status')">Status</th>
                                    <th v-if="isshowTable('mpo_name')">Nama Poli</th>
                                    <th v-if="isshowTable('ar_reg_date')">Tanggal Daftar</th>
                                    <th v-if="isshowTable('ap_code')">No. RM</th>
                                    <th v-if="isshowTable('bu_full_name')">Nama Dokter</th>
                                    <th v-if="isshowTable('mcp_name')">Cara Bayar</th>
                                    <th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
                                    <th v-if="isshowTable('ap_usia')">Usia</th>
                                    <th v-if="isshowTable('ap_gender')">Jenis Kelamin</th>
                                    <th v-if="isshowTable('ar_penunjang')">Penunjang</th>
                                    <th>No SEP</th>
                                    <th>Nama Verifikator</th>
                                    <th>Last Update E-SEP</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody v-if="!loadingTable" >
                                <template v-for="(v,k) in dataReg" >
                                    <tr :key="k" :class="v.ar_is_created_upla == 'N' ? 'table-info' : v.ar_is_created_upla == 'VOID' ? 'table-danger' : ''">
                                        <td>{{k+1}}</td>
                                        <td v-if="isshowTable('ar_reg_code')">
                                        {{v.ar_reg_code||"-"}}
                                        <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
                                        <span v-else class="text-danger">PASIEN LAMA</span>                           </td>

                                        <td v-if="isshowTable('ap_fullname')">
                                            <a v-if="v.ar_status > 6 && (v.ar_mpo_id != 59 && v.ar_mpo_id != 60)" href="javascript:;" @click="redirect(v)" data-popup="tooltip" data-toggle="modal" data-target="#confirmPatient" class="font-weight-semibold border-bottom" data-original-title="Panggil Pasien">{{uppercaseWord(v.ap_fullname)||"-"}}</a>
                                            <span v-else>{{uppercaseWord(v.ap_fullname)||"-"}}</span>
                                        
                                            <p>{{v.ap_nik||"-"}}</p>
                                            
                                            <router-link v-if="v.ar_dokter_id && v.ar_status > 4 && v.ar_is_created_pat == 'Y'" :to="{name: 'FollowUp', 
                                            params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }"
                                                v-b-tooltip.hovv-ber title="Follow Up Tindakan" class="font-weight-semibold text-success">
                                                Hasil Follow Up
                                            </router-link>
                                            
                                            <span v-if="v.ar_tanggal_ranap" class="badge bg-purple mt-2">Pasien Rawat Inap</span>
                                            <p v-if="v.ar_tanggal_ranap">{{v.ar_tanggal_ranap| moment("DD MMM YYYY")}}</p>
                                        </td>
                                        
                                        <td v-if="isshowTable('ar_status')">
                                            <template v-if="(v.ar_status <= sFisio) || (v.ar_status == 99 || v.ar_status == 98)">
                                                <span :class="`badge ${v.ars_color}`">{{uppercaseWord(v.ars_name)||'-'}}</span>     
                                            </template>

                                            <template v-else>
                                                <template>
                                                    <template v-if="v.ar_status == sPPA">
                                                        <span v-if="v.ar_is_ppa_fisio == 'Y' && v.ar_is_done_ppa_fisio !== 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Fisioterapi</span>
                                                        <span v-if="v.ar_is_ppa_radiologi == 'Y' && v.ar_is_done_ppa_radiologi !== 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Radiologi</span>
                                                        <span v-if="v.ar_is_ppa_lab == 'Y' && v.ar_is_done_ppa_lab !== 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Laboratorium</span>
                                                        <span v-if="v.ar_is_ppa_dietisien == 'Y' && v.ar_is_done_ppa_dietisien !== 'Y'" :class="'badge bg-info text-white mb-1'">Dietisien</span>
                                                    </template>
                                                    <span v-else :class="`badge ${v.ars_color}`">{{uppercaseWord(v.ars_name)||'-'}}</span>
                                                </template>
                                            </template>
                                            
                                            

                                            <span v-if="v.ar_is_belum_datang == 'Y'" :class="`badge badge-info mt-1`">{{uppercaseWord('Belum Datang')||'-'}}</span>
                                            
                                            <span v-if="v.ar_tanggal_ranap" :class="`badge badge-info mt-1`">Pasien Rawat Inap</span>
                                                                    
                                            <span v-if="v.ar_status == 99 || v.ar_status == 98" :class="`text-danger font-weight-semibold mt-1`">
                                            Diabsen Oleh : {{v.absen_by||"-"}}</span>
                                        </td>

                                        <td v-if="isshowTable('mpo_name')">{{uppercaseWord(v.mpo_name)||"-"}}</td>
                                        
                                        <td v-if="isshowTable('ar_reg_date')">
                                        {{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>      
                                        <td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">{{uppercaseWord(v.ap_code)||"-"}}
                                        <br/>
                                        <p class="badge bg-warning mt-1" v-if="v.ar_esep_status == 'M'">Menunggu Download E-SEP</p>    
                                        </td>
                                        <td v-if="isshowTable('bu_full_name')">{{uppercaseWord(v.bu_full_name)||"-"}}</td>
                                        <td v-if="isshowTable('mcp_name')">{{uppercaseWord(v.mcp_name)||"-"}}</td>
                                        <td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
                                        <td v-if="isshowTable('ap_usia')">
                                        {{v.ap_usia_with_ket||"-"}}
                                        <span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
                                        </td>
                                        <td v-if="isshowTable('ap_gender')">{{getGender(v.ap_gender)||"-"}}</td>
                                        <td v-if="isshowTable('ar_penunjang')">
                                            <div v-if="v.ar_is_ppa_radiologi == 'Y'" class="d-flex align-items-center">
                                                <i class="icon-checkmark-circle text-success"></i>
                                                <span class="ml-1">Radio</span>
                                            </div>
                                            <div v-if="v.ar_is_ppa_lab == 'Y'" class="d-flex align-items-center">
                                                <i class="icon-checkmark-circle text-success"></i>
                                                <span class="ml-1">Lab</span>
                                            </div>
                                            <div v-if="v.ar_is_ppa_fisio == 'Y'" class="d-flex align-items-center">
                                                <i class="icon-checkmark-circle text-success"></i>
                                                <span class="ml-1">Fisioterapi</span>
                                            </div>
                                            <span v-if="v.ar_is_ppa_radiologi !== 'Y' && v.ar_is_ppa_lab !== 'Y' && v.ar_is_ppa_fisio !== 'Y' && v.ar_is_ppa_dietisien !== 'Y'"> - </span>
                                        </td>
                                        <td>
                                            {{v.absd_no_sep||"-"}}
                                        </td>
                                        <td>
                                            {{v.verifikator || "-"}}
                                        </td>
                                        <td>
                                            <div v-if="v.ar_esep_generate_date">
                                                <p>{{v.ar_esep_generate_date | moment("DD MMMM YYYY, HH:mm")}}</p>
                                            </div>
                                            <span v-else> - </span>
                                        </td>
                                        <td class="text-center">
                                            <template v-if="(v.ar_mcp_id == 138 || v.ar_mcp_id == 137) && (v.ar_is_penjunjang_radiologi !== 'Y' && v.ar_is_penjunjang_lab !== 'Y')" >
                                                <template v-if="v.ar_esep_status !== 'M'">
                                                    <a href="javascript:;" @click="doCetakSEP(v,k)" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover.right title="Download E-SEP" 
                                                    ><i class="icon-file-plus"></i></a>
                                                    <br/>
                                                    <a href="javascript:;" v-if="v.ar_esep_status == 'D'" @click="doCetakSEP(v,k,1)" class="font-weight-semibold mt-2">Ambil Ulang Data</a>
                                                </template>
                                                <template v-else>
                                                    <div class="spinner-border spinner-border-sm text-info" role="status">
                                                    </div>
                                                    <span class="visually-hidden" style="font-size: 12px;">Sedang Mengunduh</span>
                                                </template>
                                            </template>
                                            <span v-else> - </span>

                                            <template v-if="user.levelId == 1 && (v.ar_reg_ppa_radiologi || v.ar_reg_ppa_lab)">
                                                 <a href="javascript:;" @click="doChangeStatusPenunjang(v)" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover.right title="Kembalikan Status Ke penunjang" 
                                                    ><i class="icon-notification2"></i></a>
                                            </template>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-if="loadingTable">
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                            </tbody>
                            <tbody v-if="loadingInvinite">
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                            </tbody>
                            <tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">   
                                <tr>
                                    <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                        Data Tidak Ditemukan
                                    </th>
                                </tr>                
                            </tbody>                
                        </table>
                        <div :class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                            <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                            <i class="icon-chevron-left"></i>
                            </a>
                            <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                            <i class="icon-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div :class="activeTab == 2 ? 'tab-pane fade show active' : 'tab-pane fade'" id="reqChangeData">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="form-row">
                                    <div class="col-md-5" v-if="isshowTable('mpo_name')">
                                        <b-form-group>
                                            <v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-6" v-if="isshowTable('ar_reg_date')">
                                        <div class="form-group mb-0 d-flex">
                                            <date-range-picker
                                                ref="picker"
                                                :locale-data="datePickerConfig.locale"
                                                :autoApply="datePickerConfig.autoApply"
                                                v-model="dateRange"
                                                :opens="'right'"
                                                :ranges="datePickerConfig.ranges"
                                                @update="updateValues"
                                            >
                                                <template v-slot:input="picker">
                                                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                </template>
                                            </date-range-picker>
                                            <div class="input-group-append calendar-group">
                                                <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                    <input class="form-control"
                                        v-model="filter.search"
                                        @input="doSearch"
                                        placeholder="Ketik Nama/No. RM Pasien"
                                    />
                                    <div class="form-control-feedback">
                                        <i class="icon-search4 text-indigo"></i>
                                    </div>
                                        
                                    <b-button
                                        class="ml-1 d-inline-flex align-items-center"
                                        variant="outline-success"
                                        id="resetBtn"
                                        @click="doResetData()"
                                    >Reset</b-button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-hover table-sm patient-table">
                            <thead>
                                <tr>
                                    <th>Tgl</th>
                                    <th>Pembuat Permintaan</th>
                                    <th>Nama Pasien</th>
                                    <th>No. RM</th>
                                    <th>No. Reg</th>
                                    <th>Status</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody v-if="!loadingTable" >
                                <template v-for="(v,k) in dataReg" >
                                    <tr :key="k" >
                                        <td>{{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>
                                        <td>
                                            <div class="font-weight-semibold">{{uppercaseWord(v.bu_full_name)||"-"}}</div>
                                            <small class="text-muted">{{uppercaseWord(v.mpo_name)||"-"}}</small>
                                        </td>
                                        <td>
                                        {{uppercaseWord(v.ap_fullname)||"-"}}
                                        </td>
                                        <td>{{v.ap_code||"-"}}</td>
                                        <td>{{v.ar_reg_code||"-"}} <br>
                                            <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
                                            <span v-else class="text-danger">PASIEN LAMA</span>
                                        </td>
                                        <td>
                                            <template v-if="v.ar_status == sPPA">
                                            <span v-if="v.ar_is_ppa_fisio == 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Fisioterapi</span>
                                                <span v-if="v.ar_is_ppa_fisio == 'Y' && v.ar_is_done_ppa_fisio !== 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Fisioterapi</span>
                                                <span v-if="v.ar_is_ppa_radiologi == 'Y' && v.ar_is_done_ppa_radiologi !== 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Radiologi</span>
                                                <span v-if="v.ar_is_ppa_lab == 'Y' && v.ar_is_done_ppa_lab !== 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Laboratorium</span>
                                                <span v-if="v.ar_is_ppa_dietisien == 'Y' && v.ar_is_done_ppa_dietisien !== 'Y'" :class="'badge bg-info text-white mb-1'">Dietisien</span>
                                            </template>
                                            <span v-else :class="`badge ${v.ars_color}`">{{uppercaseWord(v.ars_name)||'-'}}</span>
                                            <span v-if="v.ar_is_belum_datang == 'Y'" :class="`badge badge-info mt-1`">{{uppercaseWord('Belum Datang')||'-'}}</span>
                                            
                                            <span v-if="v.arm_is_draft == 'Y'" :class="`badge badge-warning mt-1`">{{uppercaseWord('Draft')||'-'}}</span>
                                                        
                                            <span v-if="v.ar_tanggal_ranap" :class="`badge badge-info mt-1`">Pasien Rawat Inap</span>

                                            <span v-if="v.ar_status == 99 || v.ar_status == 98" :class="`text-danger font-weight-semibold mt-1`">
                                            Diabsen Oleh : {{v.absen_by||"-"}}</span>
                                        </td>
                                        <td>
                                            <a href="javascript:;" @click="requestApprove(v)" data-toggle="modal" data-target="#confirmEditChanges" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" title="" data-original-title="Konfirmasi Perubahan"><i class="icon-check2"></i></a>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-if="loadingTable">
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                            </tbody>
                            <tbody v-if="loadingInvinite">
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                            </tbody>
                            <tbody v-if="!(dataReg||[]).length && !loadingTable">   
                                <tr>
                                    <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                        Data Tidak Ditemukan
                                    </th>
                                </tr>                
                            </tbody>                
                        </table>
                        
                        <div :class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                            <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                            <i class="icon-chevron-left"></i>
                            </a>
                            <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                            <i class="icon-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <b-modal 
            v-model="openFilter"
            :title="'Filter Kolom'"
            size="sm"
            ok-title="Terapkan"
            @ok="terapkan()"
        >
            <div class="row">
                <div class="col-md-12">
                <div class="wrap_line">
                    <div class="form-check p-0">
                        <label class="form-check-label d-flex">
                        <b-form-checkbox @input="checkAll($event)" v-model="selectedAll" class="form-check-input-styled" name="radio-inline-left" />
                        Tampilkan Semua
                        </label>
                    </div>
                    <hr class="my-2">
                    <div class="pl-2">
                        <b-form-group>
                            <b-form-checkbox-group
                                id="checkbox-block"
                                v-model="selectedFilter"
                                :options="optionFilter"
                                name="filter"
                            ></b-form-checkbox-group>
                        </b-form-group>
                    </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal v-model="openVerif"
            :title="'Konfirmasi Pengajuan Perubahan'"
            size="sm"
            hide-footer
        >
            <div class="modal-body p-0">
                <div class="text-center">
                <div class="modal-shout-icon mb-3 d-inline-block alpha-warning text-warning rounded-circle">
                    <i class="icon-warning2"></i>
                </div>
                <h6 class="mb-3">Konfirmasi pengajuan perubahan pengkajian?</h6>
                </div>
            </div>
            <div class="modal-footer p-0 mt-3">
                <button type="button" @click="konfirmasiApprove('N')"  class="btn btn-outline-danger" data-dismiss="modal">Tidak Setuju</button>
                <button type="button" @click="konfirmasiApprove('Y')" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>
                Ya, Setuju</button>
            </div>
        </b-modal>

    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'

export default {
    extends: GlobalVue,
    data() {
        return {
            activeTab : 1,
            mPoli: [],
            dataReg: [],
            rowEdit: {},
            loadingInvinite: false,
            openVerif: false,
            openPanggil: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            openFilter: false,
            getRequestUpla: 0,
            getVerifUpla: 0,
            onScrollPoint: false,
            tableScrollPoint: null,
            
            firstInitLoaded: [],

            optionFilter: [
                { text: 'No. Kedatangan', value: 'ar_reg_code' },
                { text: 'Nama', value: 'ap_fullname' },
                { text: 'Status', value: 'ar_status' },
                { text: 'Nama Poli', value: 'mpo_name' },
                { text: 'Tanggal Daftar', value: 'ar_reg_date' },
                { text: 'No RM.', value: 'ap_code' },
                { text: 'Nama Dokter', value: 'bu_full_name' },
                { text: 'Payor', value: 'mcp_name' },
                { text: 'Tanggal Lahir', value: 'ap_dob' },
                { text: 'Usia', value: 'ap_usia' },
                { text: 'Jenis Kelamin', value: 'ap_gender' },
                { text: 'Penunjang', value: 'ar_penunjang' },
            ],
            selectedFilter: [
                'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
            ],
            acceptedFilter: [
                'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            },
            url: 'upla'
        }
    },
  	components:{DateRangePicker},
    mounted() {
        let filterColumn = JSON.parse(localStorage.getItem(`kolumn_rekam_medis_${this.user.id}`) || '[]')
        if(filterColumn.length){
            this.selectedFilter = filterColumn
            this.acceptedFilter = filterColumn
        }

        this.filter.page = 1
        
        if(this.user.mpo_id){
            this.filter.poli = 'ALL'
        }
        
        if(this.$route.query.startDate && this.$route.query.endDate){
            this.dateRange.startDate = this.$route.query.startDate
            this.dateRange.endDate = this.$route.query.endDate
        }

        if(this.$route.query.page != 1){
            let url = {
                name:this.$route.name,
                params: (this.$route.params||{}),
                query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
            }
            this.$router.push(url).catch(()=>{})
        }else{
            this.apiGet(['list', 'master_dan_resume'], true)
        }
        
        setTimeout(()=>{
            this.setSlide()
        },1000)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.addEventListener('scroll', this.handleScroll)
        
        window.addEventListener('scroll', this.windowTableScroller)
        this.onScrollPoint = false

        setTimeout(()=>{

        let idx = (this.dataReg||[]).findIndex(x=>x.ar_id == 64999) 
        console.log(idx)

        },5000)
        this.sockets.subscribe('disp_download_esep', function(data) {
            let idx = (this.dataReg||[]).findIndex(x=>x.ar_id == data.id) 
            if(idx != -1){
                this.dataReg[idx]['ar_esep_status'] = 'D'
                this.dataReg[idx]['ar_esep_doc'] = data.file
                if(data.no_sep){
                    this.dataReg[idx]['absd_no_sep'] = data.no_sep
                }
            }
        })
    },
    
    sockets: {
        connect() {
            this.isConnected = true
        },
        disconnect() {
            this.isConnected = false
        },
        messageChannel(data) {
            this.socketMessage = data
        },
    },
    methods: {
        doChangeStatusPenunjang(row){
            this.$swal({
                title: `Kembalikan Status Ke Penunjang?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText:  `Ya`,
                cancelButtonText:  `Tidak, kembali`,        
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.loadingOverlay=true
                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data:{type:'change-status-penunjang', id: row.ar_id}}, 
                        "POST"
                    )
                    .then(()=>{
                        this.loadingOverlay = false 
                        this.$swal({
                            title: "Data Berhasil Disimpan",
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        })
                        this.apiGet(['list', 'resume'])
                    })
                    .catch(err=>{ 
                        this.loadingOverlay = false 
                        if(err){
                            err.statusType = err.status
                            err.status = "error"
                            err.message = err.response.data.message
                            err.title = err.response.data.title
                            err.messageError = err.message
                        }
                        this.doSetAlertForm(err)
                    })
                }
            })
        },
        requestApprove(v){
            this.rowEdit = v
            this.openVerif = true
        },
        konfirmasiApprove(val){
            this.loadingOverlay=true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'approval-upla', id: this.rowEdit.arm_id, arm_is_approve_upla: val}}, 
                "POST"
            ).then(()=>{
                this.loadingOverlay = false 
                this.openVerif = false
                this.$swal({
                    title: val == 'Y' ? `Permintaan Berhasil Disetujui` : `Permintaan Berhasil DiTolak`,
                    icon: 'success',
                })

                if((+this.$route.query.page||1) != 1){
                    this.doResetData()
                }else{
                    this.apiGet(['list', 'resume'])
                }

            }).catch(err=>{ 
                this.loadingOverlay = false 
                this.openVerif = false
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
            })
        },
        changeTab(e){
            this.activeTab = e
            if(e == 1){
                this.url = 'upla'
            }else{
                this.url = 'verifyUpla'
            }
            this.apiGet(['list', 'resume'])
        },
        doCetakSEP(v,k, isGenerate=false){
            let data = {exptype: 'xlsx', type: "export", id: v.ar_id, isGenerate: isGenerate}
            let self = this
            self.loadingOverlay = true
            $.ajax({
                type: "POST",
                url: process.env.VUE_APP_API_URL + `/report/${'downloadSEPCaseMixQueueVer'}?token=IXs1029102asoaksoas102901290`,
                data: data,
                cache: false,
                // xhrFields:{
                //     responseType: 'blob'
                // },
                success: data => 
                {
                    self.loadingOverlay = false
                    // var link = document.createElement('a')
                    // link.href = window.URL.createObjectURL(data)
                    // link.download = `${v.absd_no_sep}_${v.ap_fullname}_${v.ap_code}_${moment().format("YYYY-MM-DD")}.pdf`
                    // link.click()
                    
                    if(data.code != 'D'){
                        self.dataReg[k]['ar_esep_status'] = 'M'
                        self.dataReg[k]['ar_esep_generate_date'] = data.gen_date
                        return self.$swal({
                            icon: data.status,
                            title: data.message,
                        })
                    }else{
                        self.dataReg[k]['ar_esep_status'] = 'D'
                        window.open(self.uploader(data.file), '_blank')
                    }
                },
                fail: data => {
                    self.loadingOverlay = false
                    alert('Not downloaded')
                }
            })
        },
        doRefreshData(){
            this.apiGet(['list', 'master_dan_resume'], true)
            window.scrollTo(0,0)
        },
        to24Hours(date){
            return moment(date).add(48, 'hours').format('YYYY-MM-DD')
        },

        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        terapkan(){
            if(!this.selectedFilter.length){
                return this.$swal({
                    icon: 'error',
                    title: 'Minimal harus ada 1 yang dipilih'
                })
            }
            this.acceptedFilter = this.selectedFilter
            localStorage.setItem(`kolumn_rekam_medis_${this.user.id}`,JSON.stringify(this.acceptedFilter))
            this.apiGet(['list', 'resume'])
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },
        // doSearch(){
        //     this.doPageOne()
        //     this.doFilter()
        // },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },500),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        
        apiGet(loadApis = ['list', 'master', 'resume'], isReloadTable = false){
            let paramsQuery = Object.keys(this.$route.query||{}).length ? this.apiParams : this.$route.query

            this.doConvertDate()

            if((+this.$route.query.page||1) == 1 || isReloadTable){
                    this.loadingTable = true
            }

            if (loadApis.indexOf('list') > -1) {
                this.apiGetList(paramsQuery)
            }

            if (loadApis.indexOf('master_dan_resume') > -1) {
                this.apiGetMasterDanAntrian(paramsQuery)
            } else {
                if (loadApis.indexOf('master') > -1) {
                    this.apiGetMaster()
                }

                if (loadApis.indexOf('resume') > -1) {
                    this.apiGetAntrian(paramsQuery)
                }
            }
        },
        apiGetList(paramsQuery) {
            Gen.apiRest(
                "/get/"+this.modulePage+
                '/'+this.url,
                {
                        params: Object.assign({
                                selectedFilter: this.selectedFilter.join(","),
                                page : this.filter.page,
                                startDate : this.filter.startDate,
                                endDate : this.filter.endDate,
                                byPassLevel: this.user.levelId == 1 ? this.uRekamMedis : null 
                        }, paramsQuery ||{})
                }
            ).then(res=>{
                    let resp = res.data
                    this.loadingTable = false
                    this.loadingInvinite = false

                    if((+this.$route.query.page||1) !== 1){
                            let data = resp.dataReg.data
                            for(let i = 0; i < (data||[]).length; i++){
                                    this.dataReg.push(data[i])
                            }

                            if (data.length === 0) {
                                    this.totalData = this.dataReg.length
                            } else {
                                    this.totalData = resp.dataReg.total
                            }
                    }else{
                        this.totalData = resp.dataReg.total
                        this.dataReg = resp.dataReg.data
                    }   
            })
        },
        apiGetMasterDanAntrian(paramsQuery) {
            // get master & total antrian/tindakan
            Gen.apiRest(
                "/get/"+this.modulePage+
                '/getMasterDanAntrian', 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.user.levelId == 1 ? this.uUpla : null 
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data                

                this.mPoli = resp.restMaster.mPoli
                this.mDokter = resp.restMaster.mDokter
                this.mAlatBantu = resp.restMaster.mAlatBantu

                this.getQueue = resp.restAntrian.getQueue
                this.getAction = resp.restAntrian.getAction
                this.getVerifUpla = resp.restAntrian.getVerifUpla
                this.getRequestUpla = resp.restAntrian.getRequestUpla

                this.firstInitLoaded = ['master', 'resume']
            })
        },
        apiGetMaster() {
            // get data master
            Gen.apiRest(
                "/get/" + this.modulePage +
                '/getMaster'
            ).then(res => {
                let resp = res.data
                
                this.mPoli = resp.mPoli
                this.mDokter = resp.mDokter
                this.mAlatBantu = resp.mAlatBantu

                this.firstInitLoaded.push('master')
            })
        },
        apiGetAntrian(paramsQuery) {
            // get total antrian/tindakan
            Gen.apiRest(
                "/get/"+this.modulePage+
                '/getAntrian', 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.user.levelId == 1 ? this.uUpla : null 
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data          

                this.getQueue = resp.getQueue
                this.getAction = resp.getAction
                this.getVerifUpla = resp.getVerifUpla
                this.getRequestUpla = resp.getRequestUpla

                this.firstInitLoaded.push('resume')
            })
        },
        handleScroll(event){
            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')
            if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    if(this.modulePage == 'Dashboard'){
                        this.loadingInvinite = true
                        this.filter.page = (+this.$route.query.page||1) + 1

                        this.$router.push({
                            name:this.modulePage,
                            query:_.clone(this.filter)
                        }).catch(()=>{})
                    }
                }
            }
            if (this.onScrollPoint && scrollEl.scrollTop == 0) {
                this.onScrollPoint = false
                window.removeEventListener('wheel', this.tableScrollFunction, false)
            }
        },
        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },
        checkAll(e){
            if(e){
                this.selectedFilter = [
                    'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
                ]
            }else{
                this.selectedFilter = []
            }
        },
        redirect(v){
            this.loadingOverlay=true
            this.$router.push({ name: 'VerifikasiUpla', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id, 
            startDate : this.filter.startDate, endDate : this.filter.endDate} }).catch(()=>{})
            // Gen.apiRest(
            //     "/do/"+this.modulePage,
            //     {data:{type:'duplicate-icd-upla', id: v.ar_id, arm_id: v.arm_id, status: v.ar_status }}, 
            //     "POST"
            // ).then(()=>{
            //     this.loadingOverlay = false
                
            // })
        },
        tableScrollFunction(e) {
            const responsiveTable = document.querySelector('.sticky-table')
            if(responsiveTable){
                let scrollDelta = e.deltaY
                let resScrollPos = responsiveTable.scrollTop + scrollDelta
                responsiveTable.scroll({
                    left: responsiveTable.scrollLeft,
                    top: resScrollPos,
                    behavior: 'smooth'
                })
            }
        },

        setSlide(){
            const responsiveTable = document.querySelector('.sticky-table')
            const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
            const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
            if(responsiveTableScroll){
                responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                Array.from(responsiveTableScrollActions).forEach(action => {
                    action.addEventListener('click', e => {
                        const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                         
                        responsiveTable.scroll({
                            left: scrollAmount,
                            behavior: 'smooth'
                        })
                    })
                })
            }
        },
        windowTableScroller(e){
            const responsiveTable = document.querySelector('.sticky-table')
            if(responsiveTable){
                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                    this.onScrollPoint = true
                    document.documentElement.scroll(0, this.tableScrollPoint)
                    window.addEventListener('wheel', this.tableScrollFunction, false)
                }
            }
        },
    },
    created () {
        // window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        // window.removeEventListener('scroll', this.handleScroll)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)


    },
    watch:{
        $route(vNew, vOld){
            if (
                this.firstInitLoaded.indexOf('master') > -1 && 
                this.firstInitLoaded.indexOf('resume') > -1
            ) {
                if (vNew.query.page > vOld.query.page) {
                    this.apiGet(['list']) // table scroll = load list only
                } else {
                    this.apiGet(['list', 'resume'])
                }
            } else {
                this.apiGet(['list', 'master_dan_resume']) // reload all
            }

            setTimeout(()=>{
                if(this.isList){
                    this.setSlide()
                }
            },1000)
        }
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
}

</script>
