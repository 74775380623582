<template>
	<div class="content-wrapper" style="overflow: inherit;">
		<div class="page-header">
			<div class="page-header-content p-0 header-elements-md-inline mt-3">
				<div class="header-elements d-none text-center text-md-left  mb-0">
					<div class="form-group mb-0 d-flex">
						<date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
							:autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
							:ranges="datePickerConfig.ranges" @update="updateValues">
							<template v-slot:input="picker">
								{{ picker.startDate | date }} - {{ picker.endDate | date }}
							</template>
						</date-range-picker>
						<div class="input-group-append calendar-group">
							<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content p-0 pt-0">
			<div class="row mb-3 align-items-center">
				<div class="col-md-5">
					<div class="form-row align-items-center">
						<div class="col-md-8">
							<div class="form-group mb-0">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="col-md-6 col-lg-3">
					<div class="card_statistic">
						<div class="row">
							<div class="col-md-3">
								<div class="shape_card bg_1"><i class="icon-users"></i></div>
							</div>
							<div class="col-md-9">
								<h3>{{resumeData.rad_new_pasien||"0"}}</h3>
								<p>Jumlah Registrasi Pasien Baru</p>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-6 col-lg-3">
					<div class="card_statistic">
						<div class="row">
							<div class="col-md-3">
								<div class="shape_card bg_2"><i class="icon-bookmarks"></i></div>
							</div>
							<div class="col-md-9">
								<h3>{{resumeData.rad_res_poli||"0"}}</h3>
								<p>Jumlah Reservasi Poliklinik</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-3">
					<div class="card_statistic">
						<div class="row">
							<div class="col-md-3">
								<div class="shape_card bg_3"><i class="icon-bookmarks"></i></div>
							</div>
							<div class="col-md-9">
								<h3>{{resumeData.rad_res_penunjang||"0"}}</h3>
								<p>Jumlah Reservasi Penunjang</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-3">
					<div class="card_statistic">
						<div class="row">
							<div class="col-md-3">
								<div class="shape_card bg_4"><i class="icon-move-up"></i></div>
							</div>
							<div class="col-md-9">
								<h3>{{resumeData.rad_pas_dirujuk||"0"}}</h3>
								<p>Jumlah Pasien Dirujuk</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="col-md-6">
					<div class="card mb-2">
						<div class="card-header">
							<h5 class="card-title font-weight-semibold">Detail Registrasi Poliklinik </h5>
						</div>
						<div class="wrap_scroll">
							<div class="table-responsive">
								<table class="table  table-bordered table-striped">
									<thead>
										<tr>
											<th>Nama Poli</th>
											<th>Jumlah</th>
										</tr>
									</thead>
									<tbody v-if="(resumeData.dataPoli||[]).length">
										<tr v-for="(v,k) in (resumeData.dataPoli||[])" :key="k">
											<td>{{v.mpo_name||"-"}}</td>
											<td>{{v.total||"0"}}</td>
										</tr>
									</tbody>
									<tbody v-else>
										<tr>
											<td colspan="99">Tidak ada data</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-6">
					<div class="card mb-2s">
						<div class="card-header">
							<h5 class="card-title font-weight-semibold">Detail Registrasi Penunjang </h5>
						</div>
						<div class="wrap_scroll">
							<div class="table-responsive">
								<table class="table  table-bordered table-striped">
									<thead>
										<tr>
											<th>Nama Penunjang</th>
											<th>Jumlah</th>
										</tr>
									</thead>

									<tbody v-if="(resumeData.dataPenunjang||[]).length">
										<tr v-for="(v,k) in (resumeData.dataPenunjang||[])" :key="k">
											<td>{{v.mpo_name||"-"}}</td>
											<td>{{v.total||"0"}}</td>
										</tr>
									</tbody>
									<tbody v-else>
										<tr>
											<td colspan="99">Tidak ada data</td>
										</tr>
									</tbody>

								</table>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-6">
					<div class="card">
						<div class="card-header">
							<h5 class="card-title font-weight-semibold">Cara Bayar </h5>
						</div>
						<div class="card-body">
							<div class="chart-container">
								<PieChart :chartData="dataCaraBayar" :chartOptions="chartOptions" />
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-6">
					<div class="card">
						<div class="card-header">
							<h5 class="card-title font-weight-semibold">Status Pasien </h5>
						</div>
						<div class="card-body">
							<div class="chart-container">
								<PieChart :chartData="dataReservasi" :chartOptions="chartOptions" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal v-model="loadingReport" hide-footer hide-header size="sm" no-close-on-backdrop class="text-center">
			<div class="d-block text-center p-3 pt-3">
				<b-spinner style="width: 3rem; height: 3rem;" variant="info" label="Large Spinner"></b-spinner>
				<h5 class="text-info">Sedang Melakukan Penghitungan data ....</h5>
				<small>*Proses akan terjadi dalam waktu beberapa saat</small>
			</div>
		</b-modal>
	</div>
</template>

<script>

import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
// import draggable from 'vuedraggable'
// import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import $ from 'jquery'
import PieChart from '@/components/chart/PieChart'


export default {
    extends: GlobalVue,
    data() {
        return {
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            },
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            resumeData : {
                dataPenunjang : [],
                dataPoli : [],
            },
            dataCaraBayar: {},
            dataPasien: {},
            dataReservasi: {},

            chartOptions: {
                responsive: true
            },
			loadingReport: false,
        }
    },
    components:{
        DateRangePicker,PieChart
        // PerfectScrollbar
    },
    methods: {
        doFill(){
            this.doFilter()
        },
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        apiGet(isLoad = true){
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            this.doConvertDate()
            // this.loadingOverlay = true
            
            if(isLoad){
                this.loadingReport = true
            }else{
                this.loadingOverlay = true
            }

            Gen.apiRest(
                "/get/"+this.modulePage+
                '/admisi', 
                {
                    params: Object.assign({
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data
                this.resumeData = resp.resumeData
                this.dataCaraBayar = resp.resumeData.dataCaraBayar
                this.dataPasien = resp.resumeData.dataPasien
                this.dataReservasi = resp.resumeData.dataReservasi
                
                if(isLoad){
                    this.loadingReport = false
                }else{
                    this.loadingOverlay = false
                }

                // this.loadingOverlay = false
            })
        }
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    },
    mounted() {
        
        if(this.$route.query.startDate && this.$route.query.endDate){
            this.dateRange.startDate = this.$route.query.startDate
            this.dateRange.endDate = this.$route.query.endDate
        }
        
        this.apiGet(false)        
    },
    watch:{
        $route(){
            this.apiGet()
        }
    }
}

</script>

