<!-- BottomBar.vue -->
<template>
  <bottom-bar variant="warning" title="Kajian Nyeri" @load="loadData()">
    <template #header>
      <h3 class="font-weight-bold mb-0">Kajian Nyeri</h3>
    </template>

    <b-row class="mt-3 justify-content-center">
      <template v-if="isLoading">
        <b-col md="12">
          <div class="d-flex justify-content-center align-items-center">
            <b-spinner variant="warning"></b-spinner>
            <h6 class="mb-0 font-weight-bold ml-2">Loading...</h6>
          </div>
        </b-col>
      </template>

      <template v-else>
        <b-col md="4" lg="3" sm="4" v-for="(dt, k) in data" class="mt-3" :key="`lab-${k}`">
          <div class="item-todolist border-warning">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="font-weight-bold mb-0">{{ dt.ap_fullname }}</h6>
              <b-badge show variant="warning" v-if="dt.arantr_have_nilai_kritis_radio == 'Y'">Kritis</b-badge>
            </div>

            <div class="text-muted mt-3">
              <p class="mb-0">{{ dt.ap_code }}</p>
              <p class="mb-0">
                <strong> Tanggal Monitoring {{`${dt.arankpkn_date} ${dt.arankpkn_jam}` | moment("DD MMMM YYYY, HH:mm")}} </strong>
              </p>
            </div>

            <div class="text-right mt-3">
              <router-link :to="{ name: 'RanapPasienTindakanRadiologi', params: { pageSlug: dt.aranr_id } }">
                <b-button type="button" variant="warning" block>
                  Tindak Lanjut

                  <b>
                    <i class="icon-arrow-right22"></i>
                  </b>
                </b-button>
              </router-link>
            </div>
          </div>
        </b-col>
      </template>
    </b-row>
  </bottom-bar>
</template>

<script>
import BottomBar from '@/components/Ranap/BottomBar'
import Gen from '@/libs/Gen.js'

export default {
  data() {
    return {
      isLoading: false,
      data: []
    }
  },

  components: {
    BottomBar
  },

  methods: {
    loadData(){
      this.$set(this, 'isLoading', true)
      Gen.apiRest(
        "/get/" + "RanapPasienKajianNyeri", {
          params: {
            type: "get-resume-kajian",
          }
        },
      ).then(res => {
        this.$set(this, 'isLoading', false)
        this.$set(this, 'data', res.data.data)
      })
    }
  }
}
</script>
