<template>
	<div class="">
		<div class="card">
			<div class="bg-white card-header">
				<div class="row align-items-center">
					<div class="col-md-4 col-lg-5">
						<div class="form-row align-items-center">
							<div class="col-lg-4">
								<h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
							</div>

							<div class="col-lg-8" v-if="isshowTable('ar_reg_date')">
								<div class="input-group">
									<date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
										:autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
										:ranges="datePickerConfig.ranges" @update="updateValues">
										<template v-slot:input="picker">
											{{ picker.startDate | date }} - {{ picker.endDate | date }}
										</template>
									</date-range-picker>
									<div class="input-group-append calendar-group">
										<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-8 col-lg-7">
						<div class="form-row justify-content-end">
							<div class="col-md">
								<div class="form-row">
									<div class="col-md-6 col-lg-5">
										<div class="lbl_status_pasien bg_red">
											<h3>
												<i class="icon-users2"></i>
												{{getQueue}} PASIEN ANTRI
											</h3>
		
										</div>
									</div>
									<div class="col-md-6 col-lg-5">
										<div class="lbl_status_pasien bg_green">
											<h3>
												<i class="icon-user-check"></i>
												{{getAction}} DALAM TINDAKAN PERAWAT
											</h3>
										</div>
									</div>
								</div>
							</div>

							<div class="col-md-auto">
								<a href="javascript:;" data-toggle="modal"
									@click="$parent.openModalResume(filter.startDate,filter.endDate)" data-target="#FunnelData"
									class="lbl_status_pasien bg_blue">
									<h3><i class="icon-menu"></i></h3>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-header">
				<div class="row">
					<div class="col-md-8 col-lg-9">
						<div class="row g-1">

							<div class="col-md-auto">
								<div class="form-group mb-0">
									<button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
										class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover><i class="icon-spinner11"></i></button>
								</div>
							</div>
							<div class="col-md-auto">
								<div class="form-group mb-0">
									<button @click="openFilter = true" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
										class="btn btn-light" title="Atur Kolom" v-b-tooltip.hover><i class="icon-table2"></i></button>
								</div>
							</div>
							<div class="col-md">
								<div class="row g-1">
									<div class="col-md-6 col-lg-3" v-if="isshowTable('mpo_name')">
										<b-form-group class="mb-0">
											<v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli"
												label="text" :clearable="true" :reduce="v=>v.value"></v-select>
										</b-form-group>
									</div>
									<div class="col-md-6 col-lg-3">
										<b-form-group class="mb-0">
											<v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.process_status"
												:clearable="true" :options="Config.mr.configStatus" label="text" :reduce="v=>v.value"></v-select>
										</b-form-group>
									</div>
									<div class="col-md-9 col-lg-4">
										<b-form-group class="mb-0">
											<v-select placeholder="Pilih Dokter" @input="doFill" v-model="filter.dokter" :options="mDokter"
												label="text" :clearable="true" :reduce="v=>v.value"></v-select>
										</b-form-group>
									</div>
								</div>
							</div>

						</div>
					</div>
					<div class="col-md-4 col-lg-3">
						<div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
							<input class="form-control" v-model="filter.search" @input="doSearch"
								placeholder="Ketik Nama/No. RM Pasien" />
							<div class="form-control-feedback">
								<i class="icon-search4 text-indigo"></i>
							</div>

							<b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn"
								@click="doResetData()">Reset</b-button>
						</div>
					</div>
				</div>
			</div>
			<div class="table-responsive sticky-table">
				<table
					class="table table-bordered table-striped table-hover table-sm text-uppercase patient-table dashboard-table">
					<thead>
						<tr>
							<th>No.</th>
							<th v-if="isshowTable('ar_reg_code')">No. Kedatangan</th>
							<th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
							<th v-if="isshowTable('ar_status')">Status</th>
							<th v-if="isshowTable('mpo_name')">Nama Poli</th>
							<th v-if="isshowTable('ar_reg_date')">Tanggal Daftar</th>
							<th v-if="isshowTable('ap_code')">No. RM</th>
							<th v-if="isshowTable('bu_full_name')">Nama Dokter</th>
							<th v-if="isshowTable('mcp_name')">Cara Bayar</th>
							<th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
							<th v-if="isshowTable('ap_usia')">Usia</th>
							<th v-if="isshowTable('ap_gender')">Jenis Kelamin</th>
							<th v-if="isshowTable('ar_penunjang')">Penunjang</th>
							<th>Aksi</th>
						</tr>
					</thead>
					<tbody v-if="!loadingTable">
						<template v-for="(v,k) in dataReg">
							<tr :key="k"
								:class="v.ar_process_status == 'QUEUE' ? 'table-info' : v.ar_process_status == 'VOID' ? 'table-danger' : ''">
								<td>{{k+1}}</td>
								<td v-if="isshowTable('ar_reg_code')">
									{{v.ar_reg_code||"-"}}
									<span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
									<span v-else class="text-danger">PASIEN LAMA</span> </td>

								<td v-if="isshowTable('ap_fullname')">
									<a v-if="v.ar_is_void !== 'Y' && v.ar_process_status !== 'ABSEN'"
										@click="requestPanggil(v,v.ar_is_called)" href="javascript:;" data-popup="tooltip" title=""
										data-toggle="modal" data-target="#confirmPatient" class="font-weight-semibold border-bottom"
										data-original-title="Panggil Pasien">{{uppercaseWord(v.ap_fullname)||"-"}}</a>
									<span v-else>{{uppercaseWord(v.ap_fullname)||"-"}}</span>
									<p>{{v.ap_nik||"-"}}</p>

									<router-link
										v-if="v.ar_dokter_id && v.ar_status > 4 && v.ar_is_created_pat !== 'Y' && v.ar_status !== 98 && v.ar_status !== 99"
										:to="{name: 'FollowUp', 
                                params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }" v-b-tooltip.hovv-ber
										title="Follow Up Tindakan" class="font-weight-semibold text-success">
										Follow Up
									</router-link>

									<router-link
										v-if="v.ar_status > 4 && v.ar_is_need_hais == 'Y' && v.ar_status !== 98 && v.ar_status !== 99" :to="{name: 'Hais', 
                                params: {pageSlug: v.ar_id}}" v-b-tooltip.hovv-ber title="Hais"
										class="font-weight-semibold text-info">
										Hais
									</router-link>



								</td>

								<td v-if="isshowTable('ar_status')">

									<template v-if="(v.ar_status <= sFisio) || (v.ar_status == 99 || v.ar_status == 98)">
										<span v-if="v.ar_process_status == 'QUEUE' && v.ar_status == sPerawat"
											:class="`badge bg-warning`">Dalam Antrean</span>
										<span v-else :class="`badge ${v.ars_color}`">{{uppercaseWord(v.ars_name)||'-'}}</span>
									</template>

									<template v-else>
										<template v-if="v.ar_status == sPPA">
											<span v-if="v.ar_is_ppa_fisio == 'Y' && v.ar_is_done_ppa_fisio !== 'Y'"
												:class="'badge bg-blue-600 text-white mb-1'">Fisioterapi</span>
											<span v-if="v.ar_is_ppa_radiologi == 'Y' && v.ar_is_done_ppa_radiologi !== 'Y'"
												:class="'badge bg-blue-600 text-white mb-1'">Radiologi</span>
											<span v-if="v.ar_is_ppa_lab == 'Y' && v.ar_is_done_ppa_lab !== 'Y'"
												:class="'badge bg-blue-600 text-white mb-1'">Laboratorium</span>
											<span v-if="v.ar_is_ppa_dietisien == 'Y' && v.ar_is_done_ppa_dietisien !== 'Y'"
												:class="'badge bg-info text-white mb-1'">Dietisien</span>
										</template>
										<span v-else :class="`badge ${v.ars_color}`">{{uppercaseWord(v.ars_name)||'-'}}</span>
									</template>

									<span v-if="v.ar_is_belum_datang == 'Y'"
										:class="`badge badge-info mt-1`">{{uppercaseWord('Belum Datang')||'-'}}</span>

									<span v-if="v.arm_is_draft == 'Y'"
										:class="`badge badge-warning mt-1`">{{uppercaseWord('Draft')||'-'}}</span>

									<span v-if="v.ar_tanggal_ranap" :class="`badge badge-info mt-1`">Pasien Rawat Inap</span>

									<span v-if="v.ar_status == 99 || v.ar_status == 98" :class="`text-danger font-weight-semibold mt-1`">
										Diabsen Oleh : {{v.absen_by||"-"}}</span>
								</td>

								<td v-if="isshowTable('mpo_name')">{{uppercaseWord(v.mpo_name)||"-"}}</td>
								<td v-if="isshowTable('ar_reg_date')">
									{{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>
								<td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">
									{{uppercaseWord(v.ap_code)||"-"}}</td>
								<td v-if="isshowTable('bu_full_name')">{{uppercaseWord(v.bu_full_name)||"-"}}</td>
								<td v-if="isshowTable('mcp_name')">{{uppercaseWord(v.mcp_name)||"-"}}</td>
								<td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
								<td v-if="isshowTable('ap_usia')">
									{{v.ap_usia_with_ket||"-"}}
									<span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
								</td>
								<td v-if="isshowTable('ap_gender')">{{getGender(v.ap_gender)||"-"}}</td>
								<td v-if="isshowTable('ar_penunjang')">
									<div v-if="v.ar_is_ppa_radiologi == 'Y'" class="d-flex align-items-center">
										<i class="icon-checkmark-circle text-success"></i>
										<span class="ml-1">Radio</span>
									</div>
									<div v-if="v.ar_is_ppa_lab == 'Y'" class="d-flex align-items-center">
										<i class="icon-checkmark-circle text-success"></i>
										<span class="ml-1">Lab</span>
									</div>
									<div v-if="v.ar_is_ppa_fisio == 'Y'" class="d-flex align-items-center">
										<i class="icon-checkmark-circle text-success"></i>
										<span class="ml-1">Fisioterapi</span>
									</div>
									<div v-if="v.ar_is_ppa_dietisien == 'Y'" class="d-flex align-items-center">
										<i class="icon-checkmark-circle text-success"></i>
										<span class="ml-1">Dietisien</span>
									</div>
									<span
										v-if="v.ar_is_ppa_radiologi !== 'Y' && v.ar_is_ppa_lab !== 'Y' && v.ar_is_ppa_fisio !== 'Y' && v.ar_is_ppa_dietisie !== 'Y'">
										- </span>
								</td>
								<td>
									<a v-if="v.ar_status == 1 || v.ar_status == 2" href="javascript:;"
										class="btn alpha-info border-info text-info-800 btn-icon rounded-round" @click="requestEdit(v)"><i
											class="icon-cog"></i></a>
								</td>
							</tr>
						</template>
					</tbody>
					<tbody v-if="loadingTable">
						<tr>
							<td colspan="99">
								<div class="skeletal-comp"></div>
							</td>
						</tr>
						<tr>
							<td colspan="99">
								<div class="skeletal-comp"></div>
							</td>
						</tr>
						<tr>
							<td colspan="99">
								<div class="skeletal-comp"></div>
							</td>
						</tr>
					</tbody>
					<tbody v-if="loadingInvinite">
						<tr>
							<td colspan="99">
								<div class="skeletal-comp"></div>
							</td>
						</tr>
					</tbody>
					<tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">
						<tr>
							<th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
								Data Tidak Ditemukan
							</th>
						</tr>
					</tbody>
				</table>
				<div
					:class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
					<a href="javascript:;" data-scroll="left"
						class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
						<i class="icon-chevron-left"></i>
					</a>
					<a href="javascript:;" data-scroll="right"
						class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
						<i class="icon-chevron-right"></i>
					</a>
				</div>
			</div>
		</div>

		<b-modal v-model="openFilter" :title="'Filter Kolom'" size="sm" ok-title="Terapkan" @ok="terapkan()">
			<div class="row">
				<div class="col-md-12">
					<div class="wrap_line">
						<div class="form-check p-0">
							<label class="form-check-label d-flex">
								<b-form-checkbox @input="checkAll($event)" v-model="selectedAll" class="form-check-input-styled"
									name="radio-inline-left" />
								Tampilkan Semua
							</label>
						</div>
						<hr class="my-2">
						<div class="pl-2">
							<b-form-group class="mb-0">
								<b-form-checkbox-group id="checkbox-block" v-model="selectedFilter" :options="optionFilter"
									name="filter"></b-form-checkbox-group>
							</b-form-group>
						</div>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal v-model="openPersetujuan" :title="'Perubahan Data'" size="sm" body-class="p-2" hide-footer>
			<div class="modal-body p-1">
				<div class="col-md-12">
					<b-form-group class="mb-2">
						<label for="">Nama Poli</label>
						<v-select placeholder="Pilih Poli Layanan" v-model="rowEdit.ar_mpo_id" :options="mPoli2"
							@input="selectDokter($event)" label="text" :reduce="v=>v.value"></v-select>
					</b-form-group>
					<b-form-group class="mb-2">
						<label for="">Nama Dokter</label>
						<v-select placeholder="Pilih Dokter" v-model="rowEdit.ar_dokter_id" :options="mDokter" label="text"
							:reduce="v=>v.value"></v-select>
					</b-form-group>
				</div>
			</div>

			<div class="modal-footer">
				<button type="button" @click="openPersetujuan = false" class="btn btn-link" data-dismiss="modal">Batal</button>
				<button type="submit" @click="konfirmasiEdit" class="btn btn-success btn-labeled btn-labeled-left"><b><i
							class="icon-checkmark"></i></b> Ubah</button>
			</div>
		</b-modal>

	</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'

export default {
    extends: GlobalVue,
    data() {
        return {
            mPoli: [],
            mPoli2: [],
            mDokter: [],
            dataReg: [],
            rowEdit: {},
            loadingInvinite: false,
            openPersetujuan: false,
            openPanggil: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            openFilter: false,
            getQueue: 0,
            getAction: 0,
            
            optionFilter: [
                { text: 'No. Kedatangan', value: 'ar_reg_code' },
                { text: 'Nama', value: 'ap_fullname' },
                { text: 'Status', value: 'ar_status' },
                { text: 'Nama Poli', value: 'mpo_name' },
                { text: 'Tanggal Daftar', value: 'ar_reg_date' },
                { text: 'No RM.', value: 'ap_code' },
                { text: 'Nama Dokter', value: 'bu_full_name' },
                { text: 'Payor', value: 'mcp_name' },
                { text: 'Tanggal Lahir', value: 'ap_dob' },
                { text: 'Usia', value: 'ap_usia' },
                { text: 'Jenis Kelamin', value: 'ap_gender' },
                { text: 'Penunjang', value: 'ar_penunjang' },
            ],
            selectedFilter: [
                'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
            ],
            acceptedFilter: [
                'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            }
        }
    },
  	components:{DateRangePicker},
    methods: {
        doRefreshData(){
            this.apiGet(['list', 'master_dan_resume'], true)
            window.scrollTo(0,0)
        },
        otherConditional(v){
            return v.arm_created_by == this.user.id && moment().format('YYYY-MM-DD') <= this.to24Hours(v.arm_created_date) && v.ar_status != 7 
        },
        to24Hours(date){
            return moment(date).add(48, 'hours').format('YYYY-MM-DD')
        },

        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        terapkan(){
            if(!this.selectedFilter.length){
                return this.$swal({
                    icon: 'error',
                    title: 'Minimal harus ada 1 yang dipilih'
                })
            }
            this.acceptedFilter = this.selectedFilter
            localStorage.setItem(`kolumn_perawat_${this.user.id}`,JSON.stringify(this.acceptedFilter))
            this.apiGet(['list', 'master_dan_resume'], true)
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },
        
        selectDokter(e){
            let data = {
                id: e,
                type: 'get-by-poli'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                this.mDokter = res.data.mDokter   
                this.rowEdit.ar_dokter_id = null         
            })
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },1000),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        
        apiGet(loadApis = ['list', 'master', 'resume'], isReloadTable = false){
            let paramsQuery = Object.keys(this.$route.query||{}).length ? this.apiParams : this.$route.query
			
            this.doConvertDate()

            if((+this.$route.query.page||1) == 1 || isReloadTable){
                this.loadingTable = true
            }

			if (loadApis.indexOf('list') > -1) {
				this.apiGetList(paramsQuery)
			}

			if (loadApis.indexOf('master_dan_resume') > -1) {
				this.apiGetMasterDanAntrian(paramsQuery)
			} else {
				if (loadApis.indexOf('master') > -1) {
					this.apiGetMaster()
				}

				if (loadApis.indexOf('resume') > -1) {
					this.apiGetAntrian(paramsQuery)
				}
			}
        },
        apiGetList(paramsQuery) {
			Gen.apiRest(
                "/get/"+this.modulePage+
                '/admin', 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.uPerawat 
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data
                this.loadingTable = false
                this.loadingInvinite = false
 
                if((+this.$route.query.page||1) !== 1){
                    let data = resp.dataReg.data
                    for(let i = 0; i < (data||[]).length; i++){
                        this.dataReg.push(data[i])
                    }

										if (data.length === 0) {
											this.totalData = this.dataReg.length
										} else {
											this.totalData = resp.dataReg.total
										}
                }else{
										this.totalData = resp.dataReg.total
										this.dataReg = resp.dataReg.data
                }   
            })
		},
		apiGetMasterDanAntrian(paramsQuery) {
			// get master & total antrian/tindakan
			Gen.apiRest(
				"/get/"+this.modulePage+
				'/getMasterDanAntrian', 
				{
					params: Object.assign({
						selectedFilter: this.selectedFilter.join(","),
						page : this.filter.page,
						startDate : this.filter.startDate,
						endDate : this.filter.endDate,
						byPassLevel: this.uPerawat 
					}, paramsQuery ||{})
				}
			).then(res=>{
				let resp = res.data                

				this.mPoli = resp.restMaster.mPoli
				this.mDokter = resp.restMaster.mDokter
				this.mAlatBantu = resp.restMaster.mAlatBantu

				this.getQueue = resp.restAntrian.getQueue
				this.getAction = resp.restAntrian.getAction

				this.firstInitLoaded = ['master', 'resume']
			})
		},
		apiGetMaster() {
			// get data master
			Gen.apiRest(
				"/get/" + this.modulePage +
				'/getMaster'
			).then(res => {
				let resp = res.data
				
				this.mPoli = resp.mPoli
				this.mDokter = resp.mDokter
				this.mAlatBantu = resp.mAlatBantu

				this.firstInitLoaded.push('master')
			})
		},
		apiGetAntrian(paramsQuery) {
			// get total antrian/tindakan
			Gen.apiRest(
				"/get/"+this.modulePage+
				'/getAntrian', 
				{
					params: Object.assign({
						selectedFilter: this.selectedFilter.join(","),
						page : this.filter.page,
						startDate : this.filter.startDate,
						endDate : this.filter.endDate,
						byPassLevel: this.user.levelId == 1 ? this.uFisio : null 
					}, paramsQuery ||{})
				}
			).then(res=>{
				let resp = res.data          

				this.getQueue = resp.getQueue
				this.getAction = resp.getAction

				this.firstInitLoaded.push('resume')
			})
		},
        handleScroll(event){
            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')
            if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    if(this.modulePage == 'Dashboard'){
                        this.loadingInvinite = true
                        this.filter.page = (+this.$route.query.page||1) + 1

                        this.$router.push({
                            name:this.modulePage,
                            query:_.clone(this.filter)
                        }).catch(()=>{})
                    }
                }
            }
        },
        requestEdit(v){
            this.rowEdit = v
            this.openPersetujuan = true
        },
        konfirmasiEdit(){
            this.loadingOverlay=true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'send-edit', 
                    id: this.rowEdit.arm_id, 
                    mpo_id: this.rowEdit.ar_mpo_id, 
                    dokter_id : this.rowEdit.ar_dokter_id, 
                    idReg: this.rowEdit.ar_id}
                }, 
                "POST"
            ).then(()=>{
                this.loadingOverlay = false 
                this.$swal({
                    title: `Data Berhasil Di Update`,
                    icon: 'success',
                })
                
                if((+this.$route.query.page||1) != 1){
                    this.doResetData()
                }else{
                    this.apiGet(['list', 'resume'])
                }
                this.openPersetujuan = false
                    
            }).catch(err=>{ 
                this.loadingOverlay = false 
                this.openPersetujuan = false
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
            })
        },
        
        requestPanggil(v,isCalled){
            if(this.user.levelId !== 1 && this.user.levelId !== 17){
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }).catch(()=>{})
            }else{
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id, byPassLevel: (this.user.levelId == 1 || this.user.levelId == 17) ? this.uPerawat : null } }).catch(()=>{})
            }
        },

        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },
        checkAll(e){
            if(e){
                this.selectedFilter = [
                    'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
                ]
            }else{
                this.selectedFilter = []
            }
        },

        redirect(v){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id, rmNo: v.arm_id,
            typeKajian:v.arm_is_kajian_awal == 'Y' ? 'awal' : 'lanjutan' }, query: {regId: v.ar_id} }).catch(()=>{})
        },
        
        endDrag() {
            Gen.apiRest(
                "/do/" +this.modulePage, {
                data: {
                    type: 'sort-data',
                    data: this.dataReg
                }
                },
                "POST"
            )
        },
        checkNullAction: _.debounce(function(cmp){
            return $(`#${cmp}`).is(':empty')
        },500),
    },
    mounted() {
        let filterColumn = JSON.parse(localStorage.getItem(`kolumn_perawat_${this.user.id}`) || '[]')
        if(filterColumn.length){
            this.selectedFilter = filterColumn
            this.acceptedFilter = filterColumn
        }

        this.filter.page = 1
        
        if(this.user.mpo_id){
            this.filter.poli = this.user.mpo_id
        }

        this.filter.process_status = 'QUEUE'
        if(this.$route.query.page != 1){
            let url = {
                name:this.$route.name,
                params: (this.$route.params||{}),
                query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
            }
            this.$router.push(url).catch(()=>{})
        }else{
            this.apiGet(['list', 'master_dan_resume'])
        }
        
        if(this.filter.poli){
            this.filter.poli = +this.filter.poli
        }
        if(this.filter.dokter){
            this.filter.dokter = +this.filter.dokter
        }
        
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.addEventListener('scroll', this.handleScroll)
    },  
    created () {
        // window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        // window.removeEventListener('scroll', this.handleScroll)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)
    },
    watch:{
        $route(vNew, vOld){
			if (
				this.firstInitLoaded.indexOf('master') > -1 && 
				this.firstInitLoaded.indexOf('resume') > -1
			) {
                if (vNew.query.page > vOld.query.page) {
				    this.apiGet(['list']) // table scroll = load list only
                } else {
                    this.apiGet(['list', 'resume'])
                }
			} else {
				this.apiGet(['list', 'master_dan_resume']) // reload all
			}
        },
        'openPanggil'(v){
            if(!v){
                setTimeout(()=>{
                    this.rowEdit.ar_is_called = 'N'            
                },1000)
            }           
        }
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
</style>