<template>
    <div class="">
        <div class="card">
            <div class="bg-white card-header">
                <div class="row align-items-center">
                    <div class="col-md-5 col-lg-auto">
                        <div>
                            <div class="mb-1"> <h5 class="card-title font-weight-semibold">Daftar Pasien</h5></div>
                            
                            <div v-if="isshowTable('ar_reg_date')">
                                <div class="input-group">
                                    <date-range-picker
                                        ref="picker"
                                        :locale-data="datePickerConfig.locale"
                                        :autoApply="datePickerConfig.autoApply"
                                        v-model="dateRange"
                                        :opens="'right'"
                                        :ranges="datePickerConfig.ranges"
                                        @update="updateValues"
                                    >
                                        <template v-slot:input="picker">
                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                        </template>
                                    </date-range-picker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-auto col-lg">
                        <div class="form-row justify-content-end">
                            <div class="col-md-3">
                                <div @click="doFilterKritis()" class="lbl_status_pasien bg_red c-pointer" v-b-tooltip.hover :title="'Klik Untuk Memfilter Pasien Kritis'">
                                    <h3>
                                        <i class="icon-users2"></i>
                                        {{getKritis}} PASIEN KRITIS
                                    </h3>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="lbl_status_pasien bg_toqoise">
                                    <h3>
                                        <i class="icon-users2"></i>
                                        {{getQueue}} PASIEN ANTRI
                                    </h3>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="lbl_status_pasien bg_green">
                                    <h3>
                                        <i class="icon-user-check"></i>
                                        {{getAction}} DALAM TINDAKAN PERAWAT
                                    </h3>
                                </div>
                            </div>

                            <div class="col-md-auto">
                                <a href="javascript:;" data-toggle="modal" @click="$parent.openModalResume(filter.startDate,filter.endDate)" data-target="#FunnelData" class="lbl_status_pasien bg_blue">
                                    <h3><i class="icon-menu"></i></h3>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-header">
                <div class="row">
                    <div class="col-md-9">
                        <div class="form-row">
                            
                            <div class="col-md-auto">
                                <div class="form-group mb-0">
                                    <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                                </div>
                            </div> 
                            <div class="col-md-auto">
                                <div class="form-group mb-0">
                                    <button @click="openFilter = true" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Atur Kolom" v-b-tooltip.hover ><i class="icon-table2"></i></button>
                                </div>
                            </div>

                            <div class="col-md-3" v-if="isshowTable('mpo_name')">
                                <b-form-group class="mb-0">
                                    <v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </b-form-group>
                            </div>
                            <div class="col-md-3">
                                <b-form-group class="mb-0">
                                    <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.process_status" :clearable="true" :options="Config.mr.configStatus" label="text" :reduce="v=>v.value"></v-select>
                                </b-form-group>
                            </div>
                            <div class="col-md-4">
                                <b-form-group class="mb-0">
                                    <v-select placeholder="Pilih Dokter" @input="doFill" v-model="filter.dokter" :options="mDokter" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                            <input class="form-control"
                                v-model="filter.search"
                                @input="doSearch"
                                placeholder="Ketik Nama/No. RM Pasien"
                            />
                            <div class="form-control-feedback">
                                <i class="icon-search4 text-indigo"></i>
                            </div>
                                
                            <b-button
                                class="ml-1 d-inline-flex align-items-center"
                                variant="outline-success"
                                id="resetBtn"
                                @click="doResetData()"
                            >Reset</b-button>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="table-responsive sticky-table">
                <table class="table table-bordered table-striped table-hover table-sm text-uppercase patient-table dashboard-table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Panggil Pasien</th>
                            <th v-if="isRegistration">No Antrean</th>
                            <th v-if="isshowTable('ar_reg_code')">No. Kedatangan</th>
                            <th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
                            <th v-if="isshowTable('mcp_name')">Cara Bayar</th>
                            <th>Nomor SEP / <br/> Berlaku rujukan</th>
                            <th v-if="isshowTable('ar_status')">Status</th>
                            <th v-if="isshowTable('mpo_name')">Nama Poli</th>
                            <th v-if="isshowTable('ar_reg_date')">Tanggal Daftar</th>
                            <th v-if="isshowTable('ap_code')">No. RM</th>
                            <th v-if="isshowTable('bu_full_name')">Nama Dokter</th>
                            <th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
                            <th v-if="isshowTable('ap_usia')">Usia</th>
                            <th v-if="isshowTable('ap_gender')">Jenis Kelamin</th>
                            <th v-if="isshowTable('ar_penunjang')">Penunjang</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody v-if="!loadingTable">
                        <template v-for="(v,k) in dataReg">
                            <tr :key="k" :class="(v.ar_process_status == 'QUEUE' ? 'table-info' : v.ar_process_status == 'VOID' ? 'table-danger' : '') || (v.ar_is_kritis_lab == 'Y' ? 'table-danger' : '') || (v.ar_is_kritis_radiologi == 'Y' ? 'table-danger' : '')">
                                <td>{{k+1}}</td>
                                <td class="text-center">
                                    <a v-if="v.ar_is_void !== 'Y' && v.ar_process_status !== 'ABSEN'" @click="requestPanggil(v,v.ar_is_called)" href="javascript:;" data-toggle="modal" data-target="#Fasttrack" data-popup="tooltip" title="Panggil Pasien" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger" data-original-title="Panggil Pasien"><i :class="v.ar_is_called !== 'Y' && (user.levelId == 3 || user.levelId == 1) && v.ar_status == sPerawat ? 'icon-megaphone':'icon-eye'"></i></a>
                                    <span v-else> - </span>
                                    
                                    
                                    <a href="javascript:;" @click="openNilaiKritis(v)" class="btn btn-icon rounded-round btn-sm alpha-success border-success ml-1" data-popup="tooltip" v-b-tooltip.hover.right title="Lihat Nilai Kritis" 
                                    v-if="v.ar_is_kritis_lab == 'Y' || v.ar_is_kritis_radiologi == 'Y'"><i class="icon-shield2"></i></a>
                                </td>
                                <td v-if="isRegistration" >
                                    <small class=" font-weight-semibold" style="font-size: 18px;">{{v.ar_no_antrian||"-"}}</small>
                                    <span class="badge bg-primary mb-1" style="cursor: pointer;" @click="updateFastTrack(v)" v-b-tooltip.hover title="Ubah Status Fast Track" v-if="v.ar_is_fast_track == 'Y'">FAST TRACK</span>

                                    <span v-if="v.ar_is_kritis_lab == 'Y' || v.ar_is_kritis_radiologi == 'Y'" class="badge bg-danger mb-1">Kritis </span>
                                </td>
                                <td v-if="isshowTable('ar_reg_code')">
                                {{v.ar_reg_code||"-"}}
                                <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
                                <span v-else class="text-danger">PASIEN LAMA</span>                           </td>

                                <td v-if="isshowTable('ap_fullname')">
                                <span :class="isSameName(v.ap_fullname)">{{uppercaseWord(v.ap_fullname)||"-"}}</span>
                                <p>{{v.ap_nik||"-"}}</p>
                                
                                <router-link v-if="v.ar_dokter_id && v.ar_status > 4 && v.ar_is_created_pat !== 'Y' && v.ar_status !== 98 && v.ar_status !== 99 && v.ar_mpo_id !== 56" :to="{name: 'FollowUp', 
                                params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }"
                                    v-b-tooltip.hovv-ber title="Follow Up Tindakan" class="font-weight-semibold text-success">
                                    Follow Up
                                </router-link>
                                
                                <router-link v-if="v.ar_status > 4 && v.ar_is_need_hais == 'Y' && v.ar_is_have_hais !== 'Y' && v.ar_status !== 98 && v.ar_status !== 99" :to="{name: 'Hais', 
                                params: {pageSlug: v.ar_id}}"
                                    v-b-tooltip.hovv-ber title="Hais" class="font-weight-semibold text-info">
                                    Hais
                                </router-link>
                                </td>

                                <td v-if="isshowTable('mcp_name')">{{uppercaseWord(v.mcp_name)||"-"}}</td>

                                <td>
                                    <p>{{v.absd_no_sep || "-"}}</p>
                                    <template v-if="v.absd_no_sep">
                                        <span v-if="v.absd_tanggal_berlaku_rujukan">
                                            {{v.absd_tanggal_berlaku_rujukan | moment("DD MMMM YYYY")}}
                                        </span>
                                        <span v-else> - </span>
                                    </template>
                                </td>
                                
                                <td v-if="isshowTable('ar_status')">
                                
                                <template v-if="(v.ar_status <= sFisio) || (v.ar_status == 99 || v.ar_status == 98)">
                                    <span v-if="v.ar_process_status == 'QUEUE' && v.ar_status == sPerawat" :class="`badge bg-warning`">Dalam Antrean</span>
                                    <span v-else :class="`badge ${v.ars_color}`">{{uppercaseWord(v.ars_name)||'-'}}</span>     
                                </template>

                                <template v-else>
                                    <template>
                                        <template v-if="v.ar_status == sPPA">
                                            <span v-if="v.ar_is_ppa_fisio == 'Y' && v.ar_is_done_ppa_fisio !== 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Fisioterapi</span>
                                            <span v-if="v.ar_is_ppa_radiologi == 'Y' && v.ar_is_done_ppa_radiologi !== 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Radiologi</span>
                                            <span v-if="v.ar_is_ppa_lab == 'Y' && v.ar_is_done_ppa_lab !== 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Laboratorium</span>
                                            <span v-if="v.ar_is_ppa_dietisien == 'Y' && v.ar_is_done_ppa_dietisien !== 'Y'" :class="'badge bg-info text-white mb-1'">Dietisien</span>
                                        </template>
                                        <span v-else :class="`badge ${v.ars_color}`">{{uppercaseWord(v.ars_name)||'-'}}</span>
                                    </template>
                                </template>
                                
                                

                                <span v-if="v.ar_is_belum_datang == 'Y'" :class="`badge badge-info mt-1`">{{uppercaseWord('Belum Datang')||'-'}}</span>
                                
                                <span v-if="v.arm_is_draft == 'Y'" :class="`badge badge-warning mt-1`">{{uppercaseWord('Draft')||'-'}}</span>
                                
                                <span v-if="v.ar_tanggal_ranap"
                                :class="`badge badge-info mt-1`">Pasien Rawat Inap</span>
                                
                                <span v-if="v.ar_status == 99 || v.ar_status == 98" :class="`text-danger font-weight-semibold mt-1`">
                                Diabsen Oleh : {{v.absen_by||"-"}}</span>

                            
                            <!--
                             
                             -->
                                <a href="javascript:;" v-if="v.ar_is_ppa_radiologi == 'Y' || v.ar_is_ppa_lab == 'Y' || v.ar_is_ppa_fisio == 'Y' || v.ar_is_ppa_dietisien == 'Y'" data-toggle="modal" @click="doOpenPenunjang(v)" data-target="#Fasttrack" data-popup="tooltip" title="Cetak Antrian Penunjang" class="text-primary mt-2" data-original-title="Cetak Antrian Penunjang" style="font-weight: 600;">Cetak Antrian penunjang </a>
                                </td>

                                <td v-if="isshowTable('mpo_name')">{{uppercaseWord(v.mpo_name)||"-"}}</td>          
                                <td v-if="isshowTable('ar_reg_date')">
                                {{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>      
                                <td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">{{uppercaseWord(v.ap_code)||"-"}}</td>
                                <td v-if="isshowTable('bu_full_name')">{{uppercaseWord(v.bu_full_name)||"-"}}</td>
                                <td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
                                <td v-if="isshowTable('ap_usia')">
                                {{v.ap_usia_with_ket||"-"}}
                                <span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
                                </td>
                                <td v-if="isshowTable('ap_gender')">{{getGender(v.ap_gender)||"-"}}</td>
                                <td v-if="isshowTable('ar_penunjang')">
                                <div v-if="v.ar_is_ppa_radiologi == 'Y'" class="d-flex align-items-center">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">Radio</span>
                                    </div>
                                    <div v-if="v.ar_is_ppa_lab == 'Y'" class="d-flex align-items-center">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">Lab</span>
                                    </div>
                                    <div v-if="v.ar_is_ppa_fisio == 'Y'" class="d-flex align-items-center">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">Fisioterapi</span>
                                    </div>
                                    <div v-if="v.ar_is_ppa_dietisien == 'Y'" class="d-flex align-items-center">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">Dietisien</span>
                                    </div>
                                    <span v-if="v.ar_is_ppa_radiologi !== 'Y' && v.ar_is_ppa_lab !== 'Y' && v.ar_is_ppa_fisio !== 'Y' && v.ar_is_ppa_dietisie !== 'Y'"> - </span>
                                </td>
                                <td>
                                    <div :id="`isAction${v.ar_id}`">

                                    <a href="javascript:;" class="btn alpha-info border-info text-info-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover title="Ajukan Perubahan Data" @click="requestEdit(v)" v-if="moduleRole('request_edit') && v.arm_id && !v.arm_can_edit_by && !otherConditional(v)"><i class="icon-drag-left"></i></a>
                                    
                                    <a href="javascript:;" @click="redirect(v)" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover title="Edit" 
                                    v-if="(v.arm_can_edit_by && (v.arm_is_approve == 'Y' && v.arm_is_approve_upla == 'Y') || otherConditional(v)) && v.arm_id"><i class="icon-pencil7"></i></a>
                                    </div>
                                    

                                    <a href="javascript:;" v-if="v.ar_is_fast_track !== 'Y' && v.arres_is_draft !== 'Y'" data-toggle="modal" @click="doFastTrack(v)" data-target="#Fasttrack" data-popup="tooltip" v-b-tooltip.hover title="Indikasi Fast track" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger" data-original-title="Pasien Fast Track"><i class="icon-list-numbered"></i></a>

                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tbody v-if="loadingTable">
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                    </tbody>
                    <tbody v-if="loadingInvinite"> 
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                    </tbody>
                    <tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">   
                        <tr>
                            <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                Data Tidak Ditemukan
                            </th>
                        </tr>                
                    </tbody>                
                </table>
                <div :class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                    <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                    <i class="icon-chevron-left"></i>
                    </a>
                    <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                    <i class="icon-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
        
        <b-modal 
            v-model="openFilter"
            :title="'Filter Kolom'"
            size="sm"
            ok-title="Terapkan"
            @ok="terapkan()"
        >
            <div class="row">
                <div class="col-md-12">
                <div class="wrap_line">
                    <div class="form-check p-0">
                        <label class="form-check-label d-flex">
                        <b-form-checkbox @input="checkAll($event)" v-model="selectedAll" class="form-check-input-styled" name="radio-inline-left" />
                        Tampilkan Semua
                        </label>
                    </div>
                    <hr class="my-2">
                    <div class="pl-2">
                        <b-form-group class="mb-0">
                            <b-form-checkbox-group
                                id="checkbox-block"
                                v-model="selectedFilter"
                                :options="optionFilter"
                                name="filter"
                            ></b-form-checkbox-group>
                        </b-form-group>
                    </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal 
            v-model="openPersetujuan"
            :title="'Konfirmasi Ajukan Perubahan'"
            size="sm"
            body-class="p-2"
            hide-footer
        >
            <div class="modal-body">
                <div class="text-center">
                <div class="modal-shout-icon mb-3 d-inline-block alpha-warning text-warning rounded-circle">
                    <i class="icon-warning2"></i>
                </div>
                <h6 class="mb-3">Apakah Anda yakin untuk mengajukan perubahan pengkajian untuk pasien 
                {{rowEdit.ap_fullname || "-"}} ?</h6>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" @click="openPersetujuan = false" class="btn btn-link" data-dismiss="modal">Batal</button>
                <button type="submit" @click="konfirmasiEdit" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b> Ya, Ajukan</button>
            </div>
        </b-modal>


        <validation-observer
            ref="VFormPanggil"
        >
        <b-modal 
            v-model="openPanggil"
            :title="'Konfirmasi Pemanggilan Pasien'"
            size="md"
            body-class="p-2"
            hide-footer
            header-class="bg-primary" 
        >
            <div class="modal-body">
                <div class="text-center">
                <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
                    <i class="icon-megaphone"></i>
                </div>
                <h6 class="mb-0">Memanggil Pasien dengan No. Antrian {{rowEdit.ar_no_antrian}} atas Nama</h6>
                <h3 class="font-weight-semibold text-primary">{{rowEdit.ap_fullname||"-"}}</h3>
                
                <template>
                <div class="mt-1 mb-3">                
                    <a href="javascript:;" v-if="isRegistration && !isPanggil" @click="canConfirm=true;panggilAntrean(rowEdit)" class="btn btn-info btn-labeled btn-labeled-left mr-1">
                        <b><i class="icon-megaphone"></i></b>
                        Panggil Antrean
                    </a>

                    <button type="button" v-else-if="isRegistration && isPanggil" class=" btn btn-info btn-labeled btn-labeled-left mr-1" disabled>
                        <b><b-spinner small></b-spinner></b>
                        Panggil Antrean
                    </button>

                    <b-alert
                        :show="isRegistration && !isPanggil && panggilSent.countdown"
                        dismissible variant="success"
                        class="mx-auto my-2 w-50"
                        @dismissed="panggilSent.countdown=0"
                        @dismiss-count-down="(cn) => panggilSent.countdown = cn" >
                        Sedang memanggil...
                    </b-alert>
                    
                    <!-- <a href="javascript:;" v-else-if="isRegistration && isPanggil" class=" btn btn-secondary btn-labeled btn-labeled-left mr-1">
                        <b><i class="icon-megaphone"></i></b>
                        {{panggilName ? panggilName+" Sedang " :"" }} Memanggil ...
                    </a> -->
                </div>
                </template>
    
                <div class="bgKonfirmasiPasienHadir">
                    <h6>Apakah pasien hadir?</h6>
                    <b-form-group class="mt-3">
                    <b-form-radio-group
                        :options="Config.mr.StatusPanggil"
                        v-model="rowEdit.ar_is_called"
                    />
                    </b-form-group>
                    
                    <div class="form-group text-left" id="patientNotPresenceInfo" v-if="rowEdit.ar_is_called == 'N'">
                        <label for="patientNotPresent">Catatan Ketidakhadiran Pasien<strong class="text-danger">*</strong></label>
                        <b-textarea v-model="rowEdit.ar_notes" name="patientNotPresent" id="patientNotPresent" rows="3" class="form-control" placeholder="cth. pasien tidak hadir setelah dipanggil 3x"></b-textarea>
                        
                        <VValidate 
                            :name="'Catatan'" 
                            v-model="rowEdit.ar_notes" 
                            :rules="{required: 1, min: 2}"
                        />
                    </div>

                    <div class="mt-4">
                        <a href="javascript:;" @click="konfirmasiPanggil()" class="btn btn-success btn-labeled btn-labeled-left">
                        <b><i class="icon-checkmark"></i></b>
                        Konfirmasi
                        </a>
                    </div>
                </div>

                </div>
            </div>
        </b-modal>
        </validation-observer>


        <validation-observer ref="VFormFastTrack">
        <b-modal v-model="openModalFastTrack" :title="'Indikasi Fast Track'" size="sm" @ok.prevent="submitFastTrack">
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Indikasi Fast Track <small class="txt_mandatory">*</small></label>
                    <b-form-radio-group
                        :options="Config.mr.mFastTrack"
                        v-model="rowEdit.arres_is_alat_bantu"
                    />
                    <VValidate 
                        name="Indikasi Fast Track" 
                        v-model="rowEdit.arres_is_alat_bantu" 
                        :rules="{required: 1}"
                    />
                </div>

                <template v-if="rowEdit.arres_is_alat_bantu">
                    <div class="col-12" id="Formalatbantu" v-if="rowEdit.arres_is_alat_bantu == 'Y'">
                        <div class="form-group">
                        <label>Alat Bantu<small class="txt_mandatory">*</small></label>
                        <v-select placeholder="Pilih Alat Bantu" v-model="rowEdit.arres_alat_bantu"  :options="mAlatBantu" label="text" :reduce="v=>v.value"></v-select>
                        </div>
                        <VValidate 
                            name="Alat Bantu" 
                            v-model="rowEdit.arres_alat_bantu" 
                            :rules="{required: 1}"
                        />
                    </div>
                    <div class="col-12" id="FormFtLainnya" v-else>
                        <div class="form-group">
                        <textarea v-model="rowEdit.ar_fast_track_notes" name="ftNote" id="ftNote" rows="4" class="form-control"
                            placeholder="e.g. kondisi pasien sudah urgent"></textarea>
                        </div>
                        <VValidate 
                            name="Catatan Fast Track" 
                            v-model="rowEdit.ar_fast_track_notes" 
                            :rules="{required: 1}"
                        />
                    </div>
                </template>
            </div>
        </b-modal>
        </validation-observer>

        
        <b-modal v-model="openModalPenunjang" :title="'Cetak Antrian Penunjang'" size="sm" ok-only>
            <div class="row">
                <div class="form-group col-md-12">
                    <div class="table-responsive">
                        <table class="table table-bordered  table-sm patient-table text-uppercase table-sm">
                        <thead>
                            <tr>
                            <th>Deskripsi</th>
                            <th>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="rowEdit.ar_is_ppa_fisio == 'Y'">
                                <td>
                                    <div class=" font-weight-semibold">Cetak No Antrian Penunjang Fisioterapi</div>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="printPenunjang(rowEdit,'FISIO')" data-toggle="modal" data-target="#billing-detail"  class="btn btn-icon rounded-round btn-sm alpha-info border-info" data-original-title="Cetak Antrian" aria-describedby="tooltip475130"><i class="icon-printer"></i></a>
                                </td>
                            </tr>
                            
                            <tr v-if="rowEdit.ar_is_ppa_lab == 'Y'">
                                <td>
                                    <div class=" font-weight-semibold">Cetak No Antrian Penunjang Laboratorium</div>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="printPenunjang(rowEdit,'LAB')" data-toggle="modal" data-target="#billing-detail"  class="btn btn-icon rounded-round btn-sm alpha-info border-info" data-original-title="Cetak Antrian" aria-describedby="tooltip475130"><i class="icon-printer"></i></a>
                                </td>
                            </tr>
                            
                            <tr v-if="rowEdit.ar_is_ppa_radiologi == 'Y'">
                                <td>
                                    <div class=" font-weight-semibold">Cetak No Antrian Penunjang Radiologi</div>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="printPenunjang(rowEdit,'RADIOLOGI')" data-toggle="modal" data-target="#billing-detail"  class="btn btn-icon rounded-round btn-sm alpha-info border-info" data-original-title="Cetak Antrian" aria-describedby="tooltip475130"><i class="icon-printer"></i></a>
                                </td>
                            </tr>
                            
                            <tr v-if="rowEdit.ar_is_ppa_dietisien == 'Y'">
                                <td>
                                    <div class=" font-weight-semibold">Cetak No Antrian Penunjang Konsul Gizi</div>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="printPenunjang(rowEdit,'DIETISIEN')" data-toggle="modal" data-target="#billing-detail"  class="btn btn-icon rounded-round btn-sm alpha-info border-info" data-original-title="Cetak Antrian" aria-describedby="tooltip475130"><i class="icon-printer"></i></a>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal v-model="isOpenNilaiKritis" :title="'Nilai Kritis'" size="lg" ok-only>
            <NilaiKritisPoli :regId="rowEdit.ar_id" />
        </b-modal>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'
import NilaiKritisPoli from '@/components/Poli/NilaiKritisPoli.vue'

export default {
    extends: GlobalVue,
    data() {
        return {
            mPoli: [],
            mDokter: [],
            dataReg: [],
            rowEdit: {},
            loadingInvinite: false,
            openPersetujuan: false,
            openPanggil: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            openFilter: false,
            getQueue: 0,
            getKritis: 0,
            getAction: 0,
            onScrollPoint: false,
            tableScrollPoint: null,
            canConfirm: false,

			firstInitLoaded: [],
            
            optionFilter: [
                { text: 'No. Kedatangan', value: 'ar_reg_code' },
                { text: 'Nama', value: 'ap_fullname' },
                { text: 'Status', value: 'ar_status' },
                { text: 'Nama Poli', value: 'mpo_name' },
                { text: 'Tanggal Daftar', value: 'ar_reg_date' },
                { text: 'No RM.', value: 'ap_code' },
                { text: 'Nama Dokter', value: 'bu_full_name' },
                { text: 'Payor', value: 'mcp_name' },
                { text: 'Tanggal Lahir', value: 'ap_dob' },
                { text: 'Usia', value: 'ap_usia' },
                { text: 'Jenis Kelamin', value: 'ap_gender' },
                { text: 'Penunjang', value: 'ar_penunjang' },
            ],
            selectedFilter: [
                'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
            ],
            acceptedFilter: [
                'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            },
            isPanggil : false,
            panggilSent: {
                secs: 5,
                countdown: 0
            },
            panggilName: "",
            openModalFastTrack: false,
            mAlatBantu: [],
            openModalPenunjang: false,
            isOpenNilaiKritis: false
        }
    },
  	components:{DateRangePicker,NilaiKritisPoli},
    methods: {
        doFilterKritis(){
            this.filter.process_status = "ALL"
            this.filter.kritis = "Y"
            this.doFilter()
        },
        openNilaiKritis(row){
            this.isOpenNilaiKritis = true
            this.rowEdit = row
        },
        isSameName(name){
            let index = this.dataReg.findIndex(x=>x.ap_fullname == name)
            let count = 0
            for (let i = 0; i < this.dataReg.length; i++) {
                if (this.dataReg[i].ap_fullname === name) {
                    count++
                }
            }
            if(count > 1){
                return "text-warning"
            }else{
                return ""
            }
        },
        printPenunjang(row, type = ""){
            let v = row
            let times = moment(row.ar_reg_date).format('HH:mm:ss')
            let dates = moment(row.ar_reg_date).format('dddd, Do MMMM YYYY')
            let noAntrian = ''
            
            if(type == 'FISIO'){
                noAntrian = v.ar_no_antrian_ppa_fisio
            }else if(type == 'LAB'){
                noAntrian = v.ar_no_antrian_ppa_lab            
            }else if(type == 'RADIOLOGI'){
                noAntrian = v.ar_no_antrian_ppa_radiologi
            }else if(type == 'DIETISIEN'){
                noAntrian = v.ar_no_antrian_ppa_dietisien
            }else{
                noAntrian = v.ar_no_antrian
            }

            let name = v.ap_fullname
            let norm = v.ap_code
            
            let newWindow = window.open('', '', 'left=0,top=0,width=302,height=350,toolbar=0,scrollbars=0,status=0,addressbar=0'),
            document = newWindow.document.open(),
            pageContent =
                '<!DOCTYPE html>' +
                '<html>' +
                '<head>' +
                '<meta charset="utf-8" />' +
                '<title>Inventory</title>' +
                '<style type="text/css">body {-webkit-print-color-adjust: exact; font-family: Arial; }</style>' +
                '</head>' +
                '<body>' +
                `<div style="position: relative;max-width: 100%;margin: 0px auto;">
                    <div style="height:100%;position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #000;line-height: 1.5;font-size: 13px;">
                        <div>
                            <table border="0" style="border-collapse: collapse;width: 100%;">
                            <tbody><tr>
                                <td style="padding:0;">
                                <table style="border-collapse: collapse;width: 100%;">
                                    <tbody><tr>
                                    <td style="padding:8px 12px;">
                                        <div style="float: left;width: 20%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                                        <div class="logo-print"></div>
                                        <img src="/emr/img/logo-rsh-mark.png" style="width: 100%;" alt="">
                                        </div>

                                        <div style="text-transform: uppercase;float: left;width: 80%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;text-align: left;">
                                        <h1 style="margin:0;font-size: 14px;line-height: 20px;margin-bottom: 2px;">RS Harapan
                                            Magelang</h1>

                                        <p style="margin:0;font-size: 10px;">Jl. P. Senopati No. 11 Magelang </p>
                                        
                                        </div>
                                        <div style="display: table-cell; clear: both;"></div>
                                    </td>
                                    </tr>
                                </tbody></table>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:0;">
                                <table style="border-collapse: collapse;width: 100%;">
                                    <tbody>
                                    <tr>
                                        <td style="padding:12px 24px;" colspan="2">
                                        <p style="margin:  0; text-align:center; font-size:16px;">Nomor Antrian Anda: </p>
                                        <h3 style="font-weight: bold; text-transform: uppercase;letter-spacing: 6px;text-align: center;font-size: 60px;
                                            margin: 0px 0 10px 0; line-height:1;">
                                        ${ noAntrian }</h3>
                                        <div>
                                            <table border="0" style="border-collapse: collapse;width: 100%;">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                    <p style="margin:  10px 0 0 0; color: #000; font-size:12px;">Nama Pasien </p>
                                                    </td>
                                                    <td>
                                                    <p style="margin:  10px 0 0 0;  color: #000; font-size:12px;">:
                                                    ${name||'-'} </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    <p style="margin:  10px 0 0 0; color: #000; font-size:12px;">Nomor RM </p>
                                                    </td>
                                                    <td>
                                                    <p style="margin:  10px 0 0 0;  color: #000; font-size:12px;">:
                                                    ${norm||'-'} </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                        <p style="margin: 8px 0; text-align:center; font-size:12px;">Silakan menunggu panggilan di ruang tunggu 
                                        dan perhatikan nomor antrian Anda.
                                        </p>
                                        <div style="font-size: 16px;line-height: 26px; margin-top:20px; border-top:1px solid #ccc;">
                                            <p style="margin: 8px 0; font-size:12px; text-align:center; color:#000; tect-align:center;">
                                            ${ dates } ,
                                            ${ times }
                                            </p>
                                            
                                        </div>
                                        </td>
                                    </tr>

                                    </tbody>
                                </table>
                                </td>
                            </tr>
                            </tbody></table>
                        </div>
                    </div>
                </div>` +
                '</body></html>'
            document.write(pageContent)
            document.close()
            newWindow.moveTo(0, 0)
            newWindow.resizeTo(screen.width, screen.height)
            setTimeout(function() {
                newWindow.print()
                newWindow.close()
            }, 250)
        },
        doOpenPenunjang(row){
            this.rowEdit = row
            this.openModalPenunjang = true
        },
        submitFastTrack(){
            this.$refs['VFormFastTrack'].validate().then(success=>{
                if(!success) return
                this.rowEdit.type = 'update-fastrack'
                if(this.rowEdit.arres_is_alat_bantu == 'Y'){
                    this.rowEdit.ar_fast_track_notes = null
                }else{
                    this.rowEdit.arres_alat_bantu = null
                }
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data: this.rowEdit}, 
                    "POST"
                ).then(res=>{
                    this.$swal({
                        title: `Berhasil`,
                        text: 'Berhasil Mengubah Data Pasien',
                        icon: 'success',
                    })
                    this.apiGet(['list', 'resume'])
                    this.openModalFastTrack = false
                })
            })
        },
        doFastTrack(row){
            this.rowEdit = row
            this.openModalFastTrack = true
        },
        updateFastTrack(v){
            this.$swal({
                title: `Ubah Status ke Non Fast Track?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText:  `Ya, ubah status ke Non fast track!`,
                cancelButtonText:  `Tidak, kembali`,        
                customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false
            }).then(result => {
                if(result.value){
                    v.type = 'update-non-fast-track'
                    this.loadingOverlay = true
                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data: v}, 
                        "POST"
                    ).then(res=>{
                        this.loadingOverlay = false 
                        this.$swal({
                            title: `Berhasil mengubah Pasien`,
                            icon: 'success',
                        })
                        this.apiGet(['list', 'resume'])
                    })
                }
            })
        },
        panggilAntrean(data){
            data.to = this.uPerawat
            this.isPanggil = true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'panggil-unit', id: data.ar_id}}, 
                "POST"
            ).then(res=>{
                let row = res.data.row
                row.to = this.uPerawat
                this.$socket.emit('panggil_pasien', row)
                setTimeout(()=>{
                    this.isPanggil = false
                    this.panggilName = ""
                    this.panggilSent.countdown = this.panggilSent.secs
                },5000)
            })
        },
        doRefreshData(){
            this.apiGet(['list', 'master_dan_resume'], true)
            window.scrollTo(0,0)
        },
        otherConditional(v){
            return v.arm_created_by == this.user.id && moment().format('YYYY-MM-DD') <= this.to24Hours(v.arm_created_date) && v.ar_status != 7 
        },
        to24Hours(date){
            return moment(date).add(48, 'hours').format('YYYY-MM-DD')
        },

        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        terapkan(){
            if(!this.selectedFilter.length){
                return this.$swal({
                    icon: 'error',
                    title: 'Minimal harus ada 1 yang dipilih'
                })
            }
            this.acceptedFilter = this.selectedFilter
            localStorage.setItem(`kolumn_perawat_${this.user.id}`,JSON.stringify(this.acceptedFilter))
			this.apiGet(['list', 'master_dan_resume'])
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },1000),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        
		apiGet(loadApis = ['list', 'master', 'resume'], isReloadTable = false){
            let paramsQuery = Object.keys(this.$route.query||{}).length ? this.apiParams : this.$route.query
			
            this.doConvertDate()

            if((+this.$route.query.page||1) == 1 || isReloadTable){
                this.loadingTable = true
            }

			if (loadApis.indexOf('list') > -1) {
				this.apiGetList(paramsQuery)
			}

			if (loadApis.indexOf('master_dan_resume') > -1) {
				this.apiGetMasterDanAntrian(paramsQuery)
			} else {
				if (loadApis.indexOf('master') > -1) {
					this.apiGetMaster()
				}

				if (loadApis.indexOf('resume') > -1) {
					this.apiGetAntrian(paramsQuery)
				}
			}
        },
		apiGetList(paramsQuery) {
			Gen.apiRest(
                "/get/"+this.modulePage+
                '/perawat', 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.user.levelId == 1 ? this.uPerawat : null 
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data
                this.loadingTable = false
                this.loadingInvinite = false
 
                if((+this.$route.query.page||1) !== 1){
                    let data = resp.dataReg.data
                    for(let i = 0; i < (data||[]).length; i++){
                        this.dataReg.push(data[i])
                    }
                    
                    if (data.length === 0) {
                        this.totalData = this.dataReg.length
                    } else {
                        this.totalData = resp.dataReg.total
                    }
                } else {
                    this.totalData = resp.dataReg.total
                    this.dataReg = resp.dataReg.data
                }   
            })
		},
		apiGetMasterDanAntrian(paramsQuery) {
			// get master & total antrian/tindakan
			Gen.apiRest(
				"/get/"+this.modulePage+
				'/getMasterDanAntrian', 
				{
					params: Object.assign({
						selectedFilter: this.selectedFilter.join(","),
						page : this.filter.page,
						startDate : this.filter.startDate,
						endDate : this.filter.endDate,
						byPassLevel: this.user.levelId == 1 ? this.uPerawat : null 
					}, paramsQuery ||{})
				}
			).then(res=>{
				let resp = res.data                

				this.mPoli = resp.restMaster.mPoli
				this.mDokter = resp.restMaster.mDokter
				this.mAlatBantu = resp.restMaster.mAlatBantu

				this.getQueue = resp.restAntrian.getQueue
				this.getAction = resp.restAntrian.getAction
                this.getKritis = resp.restAntrian.getKritis

				this.firstInitLoaded = ['master', 'resume']
			})
		},
		apiGetMaster() {
			// get data master
			Gen.apiRest(
				"/get/" + this.modulePage +
				'/getMaster'
			).then(res => {
				let resp = res.data
				
				this.mPoli = resp.mPoli
				this.mDokter = resp.mDokter
				this.mAlatBantu = resp.mAlatBantu

				this.firstInitLoaded.push('master')
			})
		},
		apiGetAntrian(paramsQuery) {
			// get total antrian/tindakan
			Gen.apiRest(
				"/get/"+this.modulePage+
				'/getAntrian', 
				{
					params: Object.assign({
						selectedFilter: this.selectedFilter.join(","),
						page : this.filter.page,
						startDate : this.filter.startDate,
						endDate : this.filter.endDate,
						byPassLevel: this.user.levelId == 1 ? this.uPerawat : null 
					}, paramsQuery ||{})
				}
			).then(res=>{
				let resp = res.data          

				this.getQueue = resp.getQueue
				this.getAction = resp.getAction
                this.getKritis = resp.getKritis

				this.firstInitLoaded.push('resume')
			})
		},

        handleScroll(event){
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')
            if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    if(this.modulePage == 'Dashboard'){
                        this.loadingInvinite = true
                        this.filter.page = (+this.$route.query.page||1) + 1

                        this.$router.push({
                            name:this.modulePage,
                            query:_.clone(this.filter)
                        }).catch(()=>{})
                    }
                }
            }
            if (this.onScrollPoint && scrollEl.scrollTop == 0) {
                this.onScrollPoint = false
                window.removeEventListener('wheel', this.tableScrollFunction, false)
            }
        },
        requestEdit(v){
            this.rowEdit = v
            this.openPersetujuan = true
        },
        konfirmasiEdit(){
            this.loadingOverlay=true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'send-request', id: this.rowEdit.arm_id, arm_can_edit_by: this.user.id}}, 
                "POST"
            ).then(()=>{
                this.loadingOverlay = false 
                this.$swal({
                    title: `Permintaan Berhasil Dikirim`,
                    icon: 'success',
                })
                
                if((+this.$route.query.page||1) != 1){
                    this.doResetData()
                }else{
                    this.apiGet(['list', 'resume'])
                }
                this.openPersetujuan = false
                    
            }).catch(err=>{ 
                this.loadingOverlay = false 
                this.openPersetujuan = false
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
            })
        },
        
        requestPanggilByPass(v,isCalled){
            this.rowEdit = v
            this.rowEdit.ar_is_called = 'Y'

            if(isCalled == 'N'){
                if(this.user.levelId == 3 && v.ar_status == this.sPerawat){
                    this.openPanggil = true                                    
                }else if(this.user.levelId == 1 && v.ar_status == this.sPerawat){
                    this.openPanggil = true                
                }else if(this.user.levelId == 5 && v.ar_status == this.sDokter){
                    this.openPanggil = true
                }else if(this.user.levelId == 7 && v.ar_status == this.sFisio){
                    this.openPanggil = true
                }else if((this.user.levelId == 9 || this.user.levelId == 13 || this.user.levelId == 11 || this.user.levelId == 12 || this.user.levelId == 6) && v.ar_status == this.sPPA){
                    this.openPanggil = true
                }else{
                    if(this.user.levelId !== 1){
                        this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }).catch(()=>{})
                    }else{
                        this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id, byPassLevel: this.user.levelId == 1 ? this.uPerawat : null } }).catch(()=>{})
                    }
                }
                
            }else{
                if(this.user.levelId !== 1){
                    this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }).catch(()=>{})
                }else{
                    this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id, byPassLevel: this.user.levelId == 1 ? this.uPerawat : null } }).catch(()=>{})
                }
            }
        },

        requestPanggil(v,isCalled){
            this.rowEdit = v
            this.rowEdit.ar_is_called = 'Y'

            if(isCalled == 'N'){
                if(this.user.levelId == 3 && v.ar_status == this.sPerawat){
                    this.loadingOverlay = true                    
                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data:{type:'check-perawat', dokter_id: v.ar_dokter_id}}, 
                        "POST"
                    ).then(res=>{
                        this.loadingOverlay = false
                        if(res.data || this.user.levelId == 1){
                            this.openPanggil = true                
                        }else{
                            this.rowEdit.ar_is_called = 'N'
                            this.$swal({
                                title: `Tidak Dapat Memanggil Pasien`,
                                text: 'Pasien yang di tujukan tidak sesuai dengan loket',
                                icon: 'error',
                            })    
                        }
                    })
                }else if(this.user.levelId == 1 && v.ar_status == this.sPerawat){
                    this.loadingOverlay = true                                        
                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data:{type:'check-perawat', dokter_id: v.ar_dokter_id}}, 
                        "POST"
                    ).then(res=>{
                        this.loadingOverlay = false
                        if(res.data || this.user.levelId == 1){
                            this.openPanggil = true                
                        }else{
                            this.rowEdit.ar_is_called = 'N'
                            this.$swal({
                                title: `Tidak Dapat Memanggil Pasien`,
                                text: 'Pasien yang di tujukan tidak sesuai dengan loket',
                                icon: 'error',
                            })    
                        }
                    })
                }else if(this.user.levelId == 5 && v.ar_status == this.sDokter){
                    this.openPanggil = true
                }else if(this.user.levelId == 7 && v.ar_status == this.sFisio){
                    this.openPanggil = true
                }else if((this.user.levelId == 9 || this.user.levelId == 13 || this.user.levelId == 11 || this.user.levelId == 12 || this.user.levelId == 6) && v.ar_status == this.sPPA){
                    this.openPanggil = true
                }else{
                    if(this.user.levelId !== 1){
                        this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }).catch(()=>{})
                    }else{
                        this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id, byPassLevel: this.user.levelId == 1 ? this.uPerawat : null } }).catch(()=>{})
                    }
                }
                
            }else{
                if(this.user.levelId !== 1){
                    this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }).catch(()=>{})
                }else{
                    this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id, byPassLevel: this.user.levelId == 1 ? this.uPerawat : null } }).catch(()=>{})
                }
            }
        },
        konfirmasiPanggil(){
            this.$refs['VFormPanggil'].validate().then(success=>{
                if(success){
                    if(this.rowEdit.ar_is_called == 'N'){
                        this.$swal({
                            icon: 'warning',
                            title: 'Apakah Anda Yakin Pasien Tidak Hadir?',
                            showCancelButton: true,
                            confirmButtonText: 'Ya',
                            cancelButtonText: 'Tidak, kembali'
                        }).then(result => {
                            if (result.value) {
                                this.konfirmasiPanggilPostData()
                            }
                        })
                    }else{
                        this.konfirmasiPanggilPostData()                       
                    }
                }
            })
        },

        konfirmasiPanggilPostData(){
            //this.loadingOverlay = true
            this.$swal({
                html: 'Mengirim data...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    this.$swal.showLoading()
                }
            })

            this.rowEdit.type = 'panggil-pasien'
            this.rowEdit.ar_status = this.sPerawat

            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: this.rowEdit}, 
                "POST"
            ).then(res=>{
                //this.loadingOverlay = false
                if(this.rowEdit.ar_is_called !== 'Y'){
                    this.$swal().close()
                    
                    if((+this.$route.query.page||1) != 1){
                        this.doResetData()
                    }else{
                        this.apiGet(['list', 'resume'])
                    }

                    this.openPanggil = false

                    this.$swal({
                        title: `Berhasil`,
                        text: 'Berhasil Mengubah Status',
                        icon: 'success',
                    })

                }else{
                    this.$swal({
                        html: 'Redirecting...',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        didOpen: () => {
                            this.$swal.showLoading()
                        }
                    })

                    if(this.user.levelId !== 1){
                        this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.rowEdit.ap_id }, query: {regId: this.rowEdit.ar_id} }).catch(()=>{})
                    }else{
                        this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.rowEdit.ap_id }, query: {regId: this.rowEdit.ar_id, byPassLevel: this.user.levelId == 1 ? this.uPerawat : null } }).catch(()=>{})
                    }
                }
            })   
        },

        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },
        checkAll(e){
            if(e){
                this.selectedFilter = [
                    'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
                ]
            }else{
                this.selectedFilter = []
            }
        },
        approvalEdit(v){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'get-user', id: v.arm_can_edit_by}}, 
                "POST"
            ).then(res=>{
                
                let resp = res.data

                 this.$swal({
                    title: `Setujui Permintaan dari ${resp.row.bu_full_name}?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText:  `Ya!`,
                    cancelButtonText:  `Tidak, kembali`,        
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        Gen.apiRest(
                            "/do/"+this.modulePage,
                            {data:{type:'approval', id: v.arm_id, arm_is_approve: 'Y'}}, 
                            "POST"
                        ).then(()=>{
                            this.loadingOverlay = false 
                            this.$swal({
                                title: `Permintaan Berhasil Disetujui`,
                                icon: 'success',
                            })

                            if((+this.$route.query.page||1) != 1){
                                this.doResetData()
                            }else{
                                this.apiGet(['list', 'resume'])
                            }

                        }).catch(err=>{ 
                            this.loadingOverlay = false 
                            if(err){
                                err.statusType = err.status
                                err.status = "error"
                                err.message = err.response.data.message
                                err.title = err.response.data.title
                                err.messageError = err.message
                            }
                            this.doSetAlertForm(err)
                        })
                    }
                })
            })
        },
        
        redirect(v){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id, rmNo: v.arm_id,
            typeKajian:v.arm_is_kajian_awal == 'Y' ? 'awal' : 'lanjutan' }, query: {regId: v.ar_id} }).catch(()=>{})
        },
        tableScrollFunction(e) {
           const responsiveTable = document.querySelector('.table-responsive')
           if(responsiveTable){
                let scrollDelta = e.deltaY
                let resScrollPos = responsiveTable.scrollTop + scrollDelta
                responsiveTable.scroll({
                    left: responsiveTable.scrollLeft,
                    top: resScrollPos,
                    behavior: 'smooth'
                })
            }
        },
        endDrag() {
            Gen.apiRest(
                "/do/" +this.modulePage, {
                data: {
                    type: 'sort-data',
                    data: this.dataReg
                }
                },
                "POST"
            )
        },
        setSlide(){
            const responsiveTable = document.querySelector('.table-responsive')
            const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
            const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
            if(responsiveTableScroll){
                responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                Array.from(responsiveTableScrollActions).forEach(action => {
                    action.addEventListener('click', e => {
                        const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                         
                        responsiveTable.scroll({
                            left: scrollAmount,
                            behavior: 'smooth'
                        })
                    })
                })
            }
        },
        
        windowTableScroller(e){
            const responsiveTable = document.querySelector('.table-responsive')
            if(responsiveTable){
                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                if(responsiveTableScroll){
                    responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                    this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                    const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                    if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                        this.onScrollPoint = true
                        document.documentElement.scroll(0, this.tableScrollPoint)
                        window.addEventListener('wheel', this.tableScrollFunction, false)
                    }
                }
            }
        },
        checkNullAction: _.debounce(function(cmp){
            return $(`#${cmp}`).is(':empty')
        },500),
    },
	mounted() {
        let filterColumn = JSON.parse(localStorage.getItem(`kolumn_perawat_${this.user.id}`) || '[]')
        if(filterColumn.length){
            this.selectedFilter = filterColumn
            this.acceptedFilter = filterColumn
        }

        this.filter.page = 1
        
        // if(this.user.mpo_id){
        //     this.filter.poli = this.user.mpo_id
        // }
        
        if(this.$route.query.startDate && this.$route.query.endDate){
            this.dateRange.startDate = this.$route.query.startDate
            this.dateRange.endDate = this.$route.query.endDate
        }

        this.filter.process_status = 'QUEUE'
        if(this.$route.query.page != 1){
            let url = {
                name:this.$route.name,
                params: (this.$route.params||{}),
                query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
            }
			
            this.$router.push(url).catch(()=>{})
            // window.location.href = this.$router.resolve(url).href
        }else{
            this.apiGet(['list', 'master_dan_resume'])
        }

        if(this.filter.poli){
            this.filter.poli = +this.filter.poli
        }

        if(this.filter.dokter){
            this.filter.dokter = +this.filter.dokter
        }
        
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.addEventListener('scroll', this.handleScroll)
        window.addEventListener('scroll', this.windowTableScroller)
        this.onScrollPoint = false

        this.$socket.emit('new_connection',3)

        this.sockets.subscribe('disp_panggil_pasien', function(data) {
            if(data.to == 3){
                this.panggilName = data.ar_oncalled_by
                this.isPanggil = true
                setTimeout(()=>{
                    this.isPanggil = false
                    this.panggilName = ""
                },2000)
            }
        })
        
        setTimeout(()=>{
            if(this.isList){
                this.setSlide()
            }
        },1000)

    },  
    created () {
        // const stickyTable = document.querySelector('.sticky-table')
        // console.log(stickyTable)
        // stickyTable.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('scroll', this.windowTableScroller)
    },
    watch:{
        $route(vNew, vOld){
			if (
				this.firstInitLoaded.indexOf('master') > -1 && 
				this.firstInitLoaded.indexOf('resume') > -1
			) {
                if (vNew.query.page > vOld.query.page) {
				    this.apiGet(['list']) // table scroll = load list only
                } else {
                    this.apiGet(['list', 'resume'])
                }
			} else {
				this.apiGet(['list', 'master_dan_resume']) // reload all
			}
    
            setTimeout(()=>{
                if(this.isList){
                    this.setSlide()
                }
            },1000)
        },
        'openPanggil'(v){
            if(!v){
                setTimeout(()=>{
                    this.rowEdit.ar_is_called = 'N'            
                },250)
            }           
        },
        'onScrollPoint'(v){
            document.body.style.overflow = v ? 'hidden' : 'auto'
        }
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
  .bgKonfirmasiPasienHadir {
        background: #F1F5F9;
        margin: 0 -1.825rem -1.825rem;
        padding: 20px 15px;
  }
</style>