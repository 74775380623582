<template>
  <div>
    <template v-if="!loading">
       <div class="row p-2">
          <div class="col-md-12">
              <h6>Nilai Kritis Lab</h6>

              <table class="table table-bordered table-striped table-sm patient-table">
              <thead>
                  <tr>
                  <th>Hasil Pemeriksaan </th>
                  <th>Hasil</th>
                  <th>Nilai Kritis</th>
                  <th>Jam Keluar Kritis</th>
                  <th>Pelapor</th>
                  <th>Aksi</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(v,k) in (nilaiKritisLab||[])" :key="k+'hasil'">
                      <td>{{v.value}}<br/>
                      </td>
                      <td>
                          <div class="result_tab form-group mb-0">
                          <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                          </div>
                      </td>
                      <td>{{v.nilai_kritis||"-"}}<br/>
                      </td>
                      <td>{{v.jam_keluar||"-"}}<br/>
                      </td>
                      <td>{{v.dilaporkan_oleh||"-"}}<br/>
                      </td>
                      <td>
                          <a v-if="!v.dilaporkan_oleh" href="javascript:;" @click="tambahNilaiKritis(v,k,'lab')" class="btn btn-icon rounded-round btn-sm alpha-success border-success" data-popup="tooltip" v-b-tooltip.hover.right title="Laporkan Nilai Kritis"><i class="icon-add"></i></a>
                          <span v-else> - </span>
                      </td>
                  </tr>
                  <tr v-if="!(nilaiKritisLab||[]).length">
                      <td colspan="99" class="text-center">Tidak Ada Data</td>
                  </tr>
              </tbody>
              </table>

              <h6 class="mt-4">Nilai Kritis Radiologi</h6>
              <table class="table table-bordered table-striped table-sm patient-table">
              <thead>
                  <tr>
                  <th>Hasil Pemeriksaan </th>
                  <th>Hasil</th>
                  <th>Nilai Kritis</th>
                  <th>Jam Keluar Kritis</th>
                  <th>Pelapor</th>
                  <th>Aksi</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(v,k) in (nilaiKritisRadiologi||[])" :key="k+'hasil'">
                      <td>{{v.value}}<br/>
                      </td>
                      <td>
                          <div class="result_tab form-group mb-0">
                          <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                          </div>
                      </td>
                      <td>{{v.nilai_kritis||"-"}}<br/>
                      </td>
                      <td>{{v.jam_keluar||"-"}}<br/>
                      </td>
                      <td>{{v.dilaporkan_oleh||"-"}}<br/>
                      </td>
                      <td>
                          <a v-if="!v.dilaporkan_oleh" href="javascript:;" @click="tambahNilaiKritis(v,k,'radiologi')" class="btn btn-icon rounded-round btn-sm alpha-success border-success" data-popup="tooltip" v-b-tooltip.hover.right title="Laporkan Nilai Kritis"><i class="icon-add"></i></a>
                          <span v-else> - </span>
                      </td>
                  </tr>
                  <tr v-if="!(nilaiKritisRadiologi||[]).length">
                      <td colspan="99" class="text-center">Tidak Ada Data</td>
                  </tr>
              </tbody>
              </table>
          </div>
      </div>
    </template>
    <span v-else>Loading ...</span>
  </div>
</template>

<script>
  import Gen from '@/libs/Gen.js'
  export default {
    data(){
      return {
        nilaiKritisRadiologi: [],
        nilaiKritisLab: [],
        hasilKajianRadiologi: [],
        hasilKajianLab: [],
        loading: false
      }
    },
    props:{
      regId: Number
    },
    methods:{
      getData(){
        this.loading = true
        let data = {
          id: this.regId,
          type: "get-data-nilai-kritis"
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.loading = false
          this.nilaiKritisRadiologi = res.data.nilaiKritisRadiologi
          this.nilaiKritisLab = res.data.nilaiKritisLab

          this.hasilKajianRadiologi = res.data.hasilKajianRadiologi
          this.hasilKajianLab = res.data.hasilKajianLab

        })
      },

      tambahNilaiKritis(v,k,type){
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda yakin akan melaporkan nilai kritis?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
              if(result.value){
                  let data = {
                    ... v,
                    type: "verif-data-nilai-kritis",
                    regId: this.regId,
                    idx: k,
                    hasilKajianLab : this.hasilKajianLab,
                    hasilKajianRadiologi : this.hasilKajianRadiologi,
                    typeData: type,
                  }
                  this.$parent.$parent.$parent.loadingOverlay = true

                  Gen.apiRest(
                      "/do/"+'RekamMedis',
                      {data:data}, 
                      "POST"
                  ).then(res=>{
                      let resp = res.data
                      this.$parent.$parent.$parent.loadingOverlay = false
                      this.$swal({
                          title: resp.message,
                          icon: resp.status,
                          confirmButtonText: 'Ok',
                          allowOutsideClick: false,
                          allowEscapeKey: false
                      })
                      this.getData()
                  }).catch(err=>{
                      this.$parent.$parent.$parent.loadingOverlay = false
                      if (err) {
                          err.statusType = err.status
                          err.status = "error"
                          err.title = err.response?.data?.title
                          err.message = err.response?.data?.message
                          err.messageError = err.message
                      }
                      this.$parent.$parent.$parent.doSetAlertForm(err)
                  })
              }
          })
      },
    },  
    
    mounted() {
      this.getData()
    },
  }
</script>