<!-- BottomBar.vue -->
<template>
  <div class="mr-1 mt-1">
    <!-- Tombol untuk membuka bottom bar -->
    <div class="button-wrapper position-relative">
      <b-button :variant="variant" @click="$emit('load');toggleButton();" :class="[buttonClass]">{{ title }}</b-button>
      <div class="round-notification kritis-animate" v-if="showIndicator"></div>
    </div>

    <!-- Bottom Bar -->
    <transition name="slide-up">
      <div v-if="showBottomBar" class="bottom-bar p-3" :class="[`bg-${variant}`]">
        <div class="container-fluid bg-white rounded">
          <div class="bottom-bar-content pb-0 text-center position-relative">
            <b-button variant="secondary" @click="toggleButton" class="button-close">Close</b-button>
            <slot name="header">
            </slot>
          </div>

          <div class="bottom-bar-content">
            <slot></slot>            
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBottomBar: false
    }
  },

  props: {
    variant: String,
    title: String,
    buttonClass: String,
    showIndicator: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleKeydown(event) {
      if (event.key === 'Escape') {
        this.showBottomBar = false
      }
    },

    toggleButton(){
      this.showBottomBar = !this.showBottomBar
    }
  },
  
  watch: {
    showBottomBar(v){
      if(v){
        document.querySelector('body').classList.add('overflow-hidden')
        document.addEventListener('keydown', this.handleKeydown)        
      } else {
        document.querySelector('body').classList.remove('overflow-hidden')
        document.removeEventListener('keydown', this.handleKeydown)
      }
    }
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown)
  }
}
</script>

<style>
.round-notification {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: -1em;
  left: 50%;
  transform: translateX(-50%);
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1050;
  max-height: 90vh;
  overflow: auto;
}
.bottom-bar-content {
  padding: 1rem;
}
.slide-up-enter-active, .slide-up-leave-active {
  transition: transform 0.2s ease;
}
.slide-up-enter, .slide-up-leave-to /* .slide-up-leave-active in <2.1.8 */ {
  transform: translateY(100%);
}

.bottom-bar .container {
  color: #000;
}

.no-border-radius-bottom-left {
  border-bottom-left-radius: 0 !important;
}

.no-border-radius-top-left {
  border-top-left-radius: 0 !important;
}


.button-close {
  position: absolute;
  left: 1rem;
  top: 25%;
}
</style>
