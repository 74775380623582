<template>
	<div class="">
		<div class="card">
			<ul class="nav nav-justified nav-tabs nav-tabs-highlight mb-0">
				<li class="nav-item"><a href="javascript:;" @click="changeTab('R')"
						:class="isObat == 'R' ? 'nav-link active' : 'nav-link'">Obat Racikan</a></li>
				<li class="nav-item"><a href="javascript:;" @click="changeTab('J')"
						:class="isObat == 'J' ? 'nav-link active' : 'nav-link'" class="nav-link">Obat Jadi</a></li>
			</ul>
			<div class="tab-content">
				<div :class="'tab-pane fade show active'">
					<div class="bg-white card-header">
						<div class="row align-items-center">
							<div class="col-md-4 col-lg-5">
								<div class="form-row">
									<div class="col-lg-4">
										<h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
									</div>

									<div class="col-lg-8" v-if="isshowTable('ar_reg_date')">
										<div class="form-group mb-0 d-flex">
											<date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
												:autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
												:ranges="datePickerConfig.ranges" @update="updateValues">
												<template v-slot:input="picker">
													{{ picker.startDate | date }} - {{ picker.endDate | date }}
												</template>
											</date-range-picker>
											<div class="input-group-append calendar-group">
												<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-8 col-lg-7">
								<div class="form-row justify-content-end">
									<div class="col-md">
										<div class="form-row justify-content-end">
											<div class="col-md-6">
												<div class="lbl_status_pasien bg_red">
													<h3>
														<i class="icon-users2"></i>
														{{getQueue}} PENDING
													</h3>
		
												</div>
											</div>
											<div class="col-md-6">
												<div class="lbl_status_pasien bg_green">
													<h3>
														<i class="icon-user-check"></i>
														{{getAction}} SELESAI
													</h3>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-auto">
										<a href="javascript:;" @click="$parent.openModalResume(filter.startDate,filter.endDate)"
											data-toggle="modal" data-target="#FunnelData" class="lbl_status_pasien bg_blue">
											<h3><i class="icon-menu"></i></h3>
										</a>
									</div>
									<div class="col-md-auto">
										<a href="javascript:;" title="Download Laporan" v-b-tooltip.hover @click="openReport=true"
											data-toggle="modal" data-target="#FunnelData" class="lbl_status_pasien bg_blue">
											<h3><i class="icon-file-text3"></i></h3>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card-header">
				<div class="row">
					<div class="col-md-8">
						<div class="row g-1">
							<div class="col-md-auto">
								<div class="form-group mb-0">
									<button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
										class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover><i class="icon-spinner11"></i></button>
								</div>
							</div>
							<div class="col-md-auto">
								<div class="form-group mb-0">
									<button @click="openFilter = true" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
										class="btn btn-light" title="Atur Kolom" v-b-tooltip.hover><i class="icon-table2"></i></button>
								</div>
							</div>
							<div class="col-md">
								<div class="row g-1">
									<div class="col-md-6 col-xl-4" v-if="isshowTable('mpo_name')">
										<b-form-group class="mb-0">
											<v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli"
												label="text" :clearable="true" :reduce="v=>v.value"></v-select>
										</b-form-group>
									</div>
									<div class="col-md-6 col-xl-4">
										<b-form-group class="mb-0">
											<v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.process_status_farmasi"
												:options="Config.mr.configStatusFarmasi" label="text" :reduce="v=>v.value"></v-select>
										</b-form-group>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
							<input class="form-control" v-model="filter.search" @input="doSearch"
								placeholder="Ketik Nama/No. RM Pasien" />
							<div class="form-control-feedback">
								<i class="icon-search4 text-indigo"></i>
							</div>

							<b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn"
								@click="doResetData()">Reset</b-button>
						</div>

					</div>
				</div>
			</div>
			<div class="table-responsive sticky-table">
				<table class="table table-bordered table-striped table-hover table-sm patient-table dashboard-table">
					<thead>
						<tr>
							<th>No.</th>
							<th>Aksi</th>
							<th v-if="isRegistration">No Antrean</th>
							<th v-if="isshowTable('ar_status')">Status</th>
							<th v-if="isshowTable('ar_reg_code')">No. Kedatangan</th>
							<th>No SEP</th>
							<th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
							<th v-if="isshowTable('mcp_name')">Cara Bayar</th>
							<th v-if="isshowTable('mpo_name')">Nama Poli</th>
							<th v-if="isshowTable('ar_reg_date')">Tanggal Daftar</th>
							<th>Waktu Masuk Resep</th>
							<th>Waktu Pelayanan Resep</th>
							<th>Waktu Keluar Resep</th>
							<th v-if="isshowTable('ap_code')">No. RM</th>
							<th v-if="isshowTable('bu_full_name')">Nama Dokter</th>
							<th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
							<th v-if="isshowTable('ap_usia')">Usia</th>
							<th v-if="isshowTable('ap_gender')">Jenis Kelamin</th>
							<th v-if="isshowTable('ar_penunjang')">Penunjang</th>


						</tr>
					</thead>
					<tbody v-if="!loadingTable">
						<template v-for="(v,k) in dataReg">
							<tr :key="k" :class="v.ar_is_submit_farmasi != 'Y' ? 'table-warning' : ''">
								<td>
									{{v.ar_no_urut_farmasi}}
								</td>

								<td class="text-center">
									<a v-if="v.ar_is_void !== 'Y' && v.ar_process_status !== 'ABSEN'" @click="redirect(v)"
										href="javascript:;" data-toggle="modal" data-target="#Fasttrack" data-popup="tooltip"
										title="Panggil Pasien" class="ml-2 btn btn-icon rounded-round btn-sm alpha-danger border-danger"
										data-original-title="Lihat Detail"><i class="icon-eye"></i></a>
									<span v-else> - </span>


									<a v-if="v.ar_is_void !== 'Y' && v.ar_process_status !== 'ABSEN' && v.ar_is_submit_farmasi == 'Y' && v.ar_is_done_farmasi != 'Y'"
										@click="requestPanggil(v,v.ar_is_called_ppa_farmasi)" href="javascript:;" data-toggle="modal"
										data-target="#Fasttrack" data-popup="tooltip" title="Panggil Pasien"
										class="btn btn-icon rounded-round btn-sm alpha-danger border-danger ml-1"
										data-original-title="Panggil Pasien"><i class="icon-megaphone"></i></a>


								</td>

								<td v-if="isRegistration">
									<small class=" font-weight-semibold" style="font-size: 18px;">{{v.ar_no_antrian||"-"}}</small>
									<span class="badge bg-primary mb-1" v-if="v.ar_is_fast_track == 'Y'">FAST TRACK</span>
								</td>


								<td v-if="isshowTable('ar_status')">

									<template>
										<span v-if="v.ar_is_done_farmasi == 'N'" :class="`badge bg-warning`">Pending</span>
										<span v-else :class="`badge badge-success`">Selesai</span>
									</template>

									<span v-if="v.ar_is_belum_datang == 'Y'"
										:class="`badge badge-info mt-1`">{{uppercaseWord('Belum Datang')||'-'}}</span>

									<span v-if="v.ar_is_alkes == 'Y'" :class="`badge badge-info`">ALKES</span>

									<span v-if="v.ar_tanggal_ranap" :class="`badge badge-info mt-1`">Pasien Rawat Inap</span>

									<span v-if="v.ar_status == 99 || v.ar_status == 98" :class="`text-danger font-weight-semibold mt-1`">
										Diabsen Oleh : {{v.absen_by||"-"}}</span>
								</td>

								<td v-if="isshowTable('ar_reg_code')">
									{{v.ar_reg_code||"-"}}
									<span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
									<span v-else class="text-danger">PASIEN LAMA</span> 
								
									<br />
									<a href="javascript:;" @click="lihatDiagnosa(v,k)" v-b-tooltip.click.html="v.diagnosa||'...'"
										class="font-weight-semibold border-bottom"> Lihat Diagnosa </a>
										
								</td>

								<td>
									<span>{{v.absd_no_sep||"-"}}</span>
								</td>

								<td v-if="isshowTable('ap_fullname')">
									<span data-popup="tooltip" data-toggle="modal" data-target="#confirmPatient"
										class="font-weight-semibold border-bottom"
										data-original-title="Panggil Pasien">{{uppercaseWord(v.ap_fullname)||"-"}}</span>
									<p>{{v.ap_nik||"-"}}</p>
								</td>
								<td v-if="isshowTable('mcp_name')">{{uppercaseWord(v.mcp_name)||"-"}}</td>
								<td v-if="isshowTable('mpo_name')">{{uppercaseWord(v.mpo_name)||"-"}}</td>
								<td v-if="isshowTable('ar_reg_date')">
									{{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>

								<td>
									<span v-if="v.ar_come_farmasi_date">
										{{v.ar_come_farmasi_date | moment("DD MMM YYYY, HH:mm")}}
									</span>
									<span v-else> - </span>
								</td>

								<td>
									<span v-if="v.ar_waktu_submit_farmasi">
										{{v.ar_waktu_submit_farmasi | moment("DD MMM YYYY, HH:mm")}}
									</span>
									<span v-else> - </span>
								</td>

								<td>
									<span v-if="v.ar_is_done_farmasi_date">
										{{v.ar_is_done_farmasi_date | moment("DD MMM YYYY, HH:mm")}}
									</span>
									<span v-else> - </span>
								</td>


								<td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">
									{{uppercaseWord(v.ap_code)||"-"}}</td>
								<td v-if="isshowTable('bu_full_name')">{{uppercaseWord(v.bu_full_name)||"-"}}</td>
								<td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
								<td v-if="isshowTable('ap_usia')">
									{{v.ap_usia_with_ket||"-"}}
									<span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
								</td>
								<td v-if="isshowTable('ap_gender')">{{getGender(v.ap_gender)||"-"}}</td>
								<td v-if="isshowTable('ar_penunjang')">
									<div v-if="v.ar_is_ppa_radiologi == 'Y'" class="d-flex align-items-center">
										<i class="icon-checkmark-circle text-success"></i>
										<span class="ml-1">Radio</span>
									</div>
									<div v-if="v.ar_is_ppa_lab == 'Y'" class="d-flex align-items-center">
										<i class="icon-checkmark-circle text-success"></i>
										<span class="ml-1">Lab</span>
									</div>
									<div v-if="v.ar_is_ppa_fisio == 'Y'" class="d-flex align-items-center">
										<i class="icon-checkmark-circle text-success"></i>
										<span class="ml-1">Fisioterapi</span>
									</div>
									<div v-if="v.ar_is_ppa_dietisien == 'Y'" class="d-flex align-items-center">
										<i class="icon-checkmark-circle text-success"></i>
										<span class="ml-1">Dietisien</span>
									</div>
									<span
										v-if="v.ar_is_ppa_radiologi !== 'Y' && v.ar_is_ppa_lab !== 'Y' && v.ar_is_ppa_fisio !== 'Y' && v.ar_is_ppa_dietisie !== 'Y'">
										- </span>
								</td>
							</tr>
						</template>
					</tbody>
					<tbody v-if="loadingTable">
						<tr>
							<td colspan="99">
								<div class="skeletal-comp"></div>
							</td>
						</tr>
						<tr>
							<td colspan="99">
								<div class="skeletal-comp"></div>
							</td>
						</tr>
						<tr>
							<td colspan="99">
								<div class="skeletal-comp"></div>
							</td>
						</tr>
					</tbody>
					<tbody v-if="loadingInvinite">
						<tr>
							<td colspan="99">
								<div class="skeletal-comp"></div>
							</td>
						</tr>
					</tbody>
					<tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">
						<tr>
							<th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
								Data Tidak Ditemukan
							</th>
						</tr>
					</tbody>
				</table>
				<div
					:class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
					<a href="javascript:;" data-scroll="left"
						class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
						<i class="icon-chevron-left"></i>
					</a>
					<a href="javascript:;" data-scroll="right"
						class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
						<i class="icon-chevron-right"></i>
					</a>
				</div>
			</div>
		</div>

		<b-modal v-model="openFilter" :title="'Filter Kolom'" size="sm" ok-title="Terapkan" @ok="terapkan()">
			<div class="row">
				<div class="col-md-12">
					<div class="wrap_line">
						<div class="form-check p-0">
							<label class="form-check-label d-flex">
								<b-form-checkbox @input="checkAll($event)" v-model="selectedAll" class="form-check-input-styled"
									name="radio-inline-left" />
								Tampilkan Semua
							</label>
						</div>
						<hr class="my-2">
						<div class="pl-2">
							<b-form-group>
								<b-form-checkbox-group id="checkbox-block" v-model="selectedFilter" :options="optionFilter"
									name="filter"></b-form-checkbox-group>
							</b-form-group>
						</div>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal v-model="openPanggil" :title="'Konfirmasi Pemanggilan Pasien'" size="md" body-class="p-2" header-class="bg-primary" hide-footer>
			<div class="modal-body">
				<div class="text-center">
					<div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
						<i class="icon-megaphone"></i>
					</div>
					<h6 class="mb-0">Memanggil Pasien dengan No. Antrian {{rowEdit.ar_no_antrian}} atas Nama</h6>
					<h3 class="font-weight-semibold text-primary">{{rowEdit.ap_fullname||"-"}}</h3>

					<div class="mt-1 mb-3">
						<a href="javascript:;" v-if="isRegistration && !isPanggil" @click="canConfirm=true;panggilAntrean(rowEdit)"
							class="btn btn-info btn-labeled btn-labeled-left mr-1">
							<b><i class="icon-megaphone"></i></b>
							Panggil Antrean
						</a>

						<a href="javascript:;" v-else-if="isRegistration && isPanggil"
							class=" btn btn-secondary btn-labeled btn-labeled-left mr-1">
							<b><i class="icon-megaphone"></i></b>
							Memanggil ...
						</a>
					</div>

					<template>
						<h6>Apakah pasien hadir?</h6>
						<b-form-group class="mt-3">
							<b-form-radio-group :options="Config.mr.StatusPanggil" v-model="rowEdit.ar_is_called_ppa_farmasi" />
						</b-form-group>
						<div class="mt-4">
							<a href="javascript:;" @click="konfirmasiPanggil()" class="btn btn-success btn-labeled btn-labeled-left">
								<b><i class="icon-checkmark"></i></b>
								Konfirmasi
							</a>
						</div>
					</template>
				</div>
			</div>
		</b-modal>

		<b-modal v-model="detailData" :title="'Resep Dokter'" size="md" body-class="p-2" hide-footer>
			<div class="modal-body">
				<div class="row">
					<div class="col-md-12">
						<table class="table table-striped">
							<thead>
								<tr>
									<th>#</th>
									<th>Jenis</th>
									<th>Nama</th>
									<th>Jumlah</th>
									<th>Frekuensi</th>
									<th>keterangan</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in resepDokter" :key="v.id">
									<td>{{k+1}}</td>
									<td>
										{{getConfigDynamic(Config.mr.configResep,v.ard_jenis)||"-"}}
									</td>
									<td>{{v.ard_nama}}</td>
									<td>{{v.ard_jumlah||"-"}} {{v.ard_satuan||"-"}}</td>
									<td>
										<span v-if="v.ard_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
										<span v-else>{{v.ard_frekuensi_lainnya || "-"}}</span>
									</td>
									<td>{{v.ard_keterangan || "-"}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</b-modal>


		<validation-observer ref="VFormReport">
			<b-modal v-model="openReport" :title="'Download Laporan Farmasi'" size="sm" ok-title="Unduh Laporan"
				@ok="downloadReport()">
				<div class="row">
					<div class="col-md-12">
						<div class="form-group mb-0 d-flex report-field">
							<date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
								:autoApply="datePickerConfig.autoApply" v-model="dateRangeReport" :opens="'right'"
								:ranges="datePickerConfig.ranges" @update="updateValues">
								<template v-slot:input="picker">
									{{ picker.startDate | date }} - {{ picker.endDate | date }}
								</template>
							</date-range-picker>
							<div class="input-group-append calendar-group">
								<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
							</div>
						</div>
					</div>
				</div>
			</b-modal>
		</validation-observer>


		<b-modal v-model="loadingReport" hide-footer hide-header size="sm" no-close-on-backdrop class="text-center">
			<div class="d-block text-center p-3 pt-3">
				<b-spinner style="width: 3rem; height: 3rem;" variant="info" label="Large Spinner"></b-spinner>
				<h5 class="text-info">Sedang Melakukan Download Laporan ....</h5>
				<small>*Pengunduhan Laporan akan terjadi dalam waktu beberapa saat</small>
			</div>
		</b-modal>
	</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'

export default {
    extends: GlobalVue,
    data() {
        return {
            mPoli: [],
            dataReg: [],
            rowEdit: {},
            loadingInvinite: false,
            openPersetujuan: false,
            openPanggil: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            openFilter: false,
            getQueue: 0,
            getAction: 0,
            onScrollPoint: false,
            tableScrollPoint: null,
            canConfirm: false,
            
						firstInitLoaded: [],

            optionFilter: [
                { text: 'No. Kedatangan', value: 'ar_reg_code' },
                { text: 'Nama', value: 'ap_fullname' },
                { text: 'Status', value: 'ar_status' },
                { text: 'Nama Poli', value: 'mpo_name' },
                { text: 'Tanggal Daftar', value: 'ar_reg_date' },
                { text: 'No RM.', value: 'ap_code' },
                { text: 'Nama Dokter', value: 'bu_full_name' },
                { text: 'Payor', value: 'mcp_name' },
                { text: 'Tanggal Lahir', value: 'ap_dob' },
                { text: 'Usia', value: 'ap_usia' },
                { text: 'Jenis Kelamin', value: 'ap_gender' },
                { text: 'Penunjang', value: 'ar_penunjang' },
            ],
            selectedFilter: [
                'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
            ],
            acceptedFilter: [
                'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            },
            isObat: 'R',
            isPanggil: false,
            detailData: false,
            resepDokter: [],

			loadingReport: false,
            openReport: false, 
            dateRangeReport: {
                startDate: new Date(),
                endDate: new Date(),
            },

        }
    },
    computed: {    
        countSkrining(){
            let total = 0
            for(let i = 0; i < (this.dataGizi.asg_data||[]).length; i++){
                if(this.dataGizi.asg_data[i]['value']){
                    total += 1
                }
            }
            return total
        }
    },
  	components:{DateRangePicker},
    methods: {
				getConfigDynamic(master,value){
						let text = ''
						if(value){
								let index = (master||[]).findIndex(x => x.value == value)
								if(index !== -1){
										text = master[index]['text']
								}
						}
						return text
				},
        lihatDiagnosa(v,k){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'lihat-diagnosa', id: v.ar_id, arm_id: v.arm_id}}, 
                "POST"
            ).then(res=>{
                this.dataReg[k]['diagnosa'] = res.data        
            })
        },
        downloadReport(){
            this.$refs['VFormReport'].validate().then(success=>{
                if(success){                
                    let startDate = moment(this.dateRangeReport.startDate).format('YYYY-MM-DD')
                    let endDate = moment(this.dateRangeReport.endDate).format('YYYY-MM-DD')
        
                    this.loadingReport = true
                    
                    let data = {
                        exptype: 'xlsx', type: "export", startDate: startDate, endDate: endDate, isObat: this.isObat
                    }

			        let self = this
                    $.ajax({
                            type: "POST",
                            url: process.env.VUE_APP_API_URL + `/report/ReportFarmasiList?token=IXs1029102asoaksoas102901290`,
                            data: data,
                            cache: false,
                            xhrFields:{
                                responseType: 'blob'
                            },
                            success: data => 
                            {
                                self.loadingOverlay = false
                                self.loadingReport = false
                                
                                var link = document.createElement('a')
                                link.href = window.URL.createObjectURL(data)
                                if(self.isObat == 'J'){
                                    link.download = `Report Obat Jadi Farmasi-${moment().format("YYYY-MM-DD")}.xlsx`
                                }else{
                                    link.download = `Report Obat Racikan Farmasi-${moment().format("YYYY-MM-DD")}.xlsx`
                                }
                                link.click()
                            },
                            fail: data => {
                                self.loadingOverlay = false
                                alert('Not downloaded')
                            }
                    })

                }
            })
        },
        openDetail(v){
            this.loadingOverlay = true    
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'lihat-resep', id: v.ar_id}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false
                this.detailData = true
                this.resepDokter = res.data.resepDokter
            })
            
        },
        panggilAntrean(data){
            
            this.isPanggil = true
           
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'panggil-antrean', id: data.ar_id, unit_id: this.user.bu_unit_id}}, 
                "POST"
            ).then(res=>{
                let row = res.data.row
                row.to = "Farmasi"
                this.$socket.emit('panggil_pasien', row)
                setTimeout(()=>{
                    this.isPanggil = false
                },5000)
            })
        },
        konfirmasiPanggil(){
            if(this.rowEdit.ar_is_called_ppa_farmasi == 'N'){
                this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin Pasien Tidak Hadir?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if (result.value) {
                        this.konfirmasiPanggilPostData()
                    }
                })
            }else{
                this.konfirmasiPanggilPostData()                       
            }
        },
        konfirmasiPanggilPostData(){
            this.rowEdit.type = 'panggil-pasien-farmasi'
            this.loadingOverlay = true            
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: this.rowEdit}, 
                "POST"
            ).then(res=>{
                this.$socket.emit('refresh_data', {to : "Farmasi"})                       

                let dataSocket = {
                    to : "Farmasi",
                    from : "Farmasi",
                    no_antrian: this.rowEdit.ar_no_antrian
                }
                
                this.$socket.emit('done_penunjang', dataSocket)

                let resp = res.data
                this.$swal({
                    title: resp.message,
                    icon: "success",
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                })
                this.openPanggil = false
                this.loadingOverlay = false
                this.apiGet(['list', 'resume'])

            })
        },
        requestPanggil(v,isCalled){
            this.rowEdit = v
            this.rowEdit.ar_is_called_ppa_farmasi = 'Y'
            this.openPanggil = true
            // <!--
            // if(isCalled == 'N'){
            //     if(this.rowEdit.ar_process_status_ppa_farmasi == 'QUEUE'){
            //         this.openPanggil = true
            //     }else{
            //         this.redirect(v)
            //     }
            // }else{
            //     this.redirect(v)
            // }
        },
        changeTab(isObat){
            this.isObat = isObat
            this.apiGet(['list', 'resume'])
        },
        doRefreshData(){
            this.apiGet(['list', 'master_dan_resume']) // reload all
            window.scrollTo(0,0)
        },
        otherConditional(v){
            return v.arm_created_by == this.user.id && moment().format('YYYY-MM-DD') <= this.to24Hours(v.arm_created_date) && v.ar_status != 7 
        },
        to24Hours(date){
            return moment(date).add(48, 'hours').format('YYYY-MM-DD')
        },

        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        terapkan(){
            if(!this.selectedFilter.length){
                return this.$swal({
                    icon: 'error',
                    title: 'Minimal harus ada 1 yang dipilih'
                })
            }
            this.acceptedFilter = this.selectedFilter
            localStorage.setItem(`kolumn_farmasi_${this.user.id}`,JSON.stringify(this.acceptedFilter))
			this.apiGet(['list', 'master_dan_resume'])
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        }, 500),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        
        apiGet(loadApis = ['list', 'master', 'resume'], isReloadTable = false){
            let paramsQuery = Object.keys(this.$route.query||{}).length ? this.apiParams : this.$route.query
			
            this.doConvertDate()

            if((+this.$route.query.page||1) == 1 || isReloadTable){
                this.loadingTable = true
            }

			if (loadApis.indexOf('list') > -1) {
				this.apiGetList(paramsQuery)
			}

			if (loadApis.indexOf('master_dan_resume') > -1) {
				this.apiGetMasterDanAntrian(paramsQuery)
			} else {
				if (loadApis.indexOf('master') > -1) {
					this.apiGetMaster()
				}

				if (loadApis.indexOf('resume') > -1) {
					this.apiGetAntrian(paramsQuery)
				}
			}
        },
        apiGetList(paramsQuery) {
			Gen.apiRest(
                "/get/"+this.modulePage+
                '/farmasi', 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.user.levelId == 1 ? this.uFarmasi : null,
                        process_status: null,
                        isObat: this.isObat
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data
                this.loadingTable = false
                this.loadingInvinite = false
 
                if((+this.$route.query.page||1) !== 1){
                    let data = resp.dataReg.data
                    for(let i = 0; i < (data||[]).length; i++){
                        this.dataReg.push(data[i])
                    }

										if (data.length === 0) {
                        this.totalData = this.dataReg.length
                    } else {
                        this.totalData = resp.dataReg.total
                    }
                }else{
										this.totalData = resp.dataReg.total
                    this.dataReg = resp.dataReg.data
                }   
            })
		},
		apiGetMasterDanAntrian(paramsQuery) {
			// get master & total antrian/tindakan
			Gen.apiRest(
				"/get/"+this.modulePage+
				'/getMasterDanAntrian', 
				{
					params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.user.levelId == 1 ? this.uFarmasi : null,
                        process_status: null,
                        isObat: this.isObat
					}, paramsQuery ||{})
				}
			).then(res=>{
				let resp = res.data                

				this.mPoli = resp.restMaster.mPoli
				this.mDokter = resp.restMaster.mDokter
				this.mAlatBantu = resp.restMaster.mAlatBantu

				this.getQueue = resp.restAntrian.getQueue
				this.getAction = resp.restAntrian.getAction

				this.firstInitLoaded = ['master', 'resume']
			})
		},
		apiGetMaster() {
			// get data master
			Gen.apiRest(
				"/get/" + this.modulePage +
				'/getMaster'
			).then(res => {
				let resp = res.data
				
				this.mPoli = resp.mPoli
				this.mDokter = resp.mDokter
				this.mAlatBantu = resp.mAlatBantu

				this.firstInitLoaded.push('master')
			})
		},
		apiGetAntrian(paramsQuery) {
			// get total antrian/tindakan
			Gen.apiRest(
				"/get/"+this.modulePage+
				'/getAntrian', 
				{
					params: Object.assign({
						selectedFilter: this.selectedFilter.join(","),
						page : this.filter.page,
						startDate : this.filter.startDate,
						endDate : this.filter.endDate,
						byPassLevel: this.user.levelId == 1 ? this.uFisio : null 
					}, paramsQuery ||{})
				}
			).then(res=>{
				let resp = res.data          

				this.getQueue = resp.getQueue
				this.getAction = resp.getAction

				this.firstInitLoaded.push('resume')
			})
		},
        handleScroll(event){
            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')
            if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    if(this.modulePage == 'Dashboard'){
                        this.loadingInvinite = true
                        this.filter.page = (+this.$route.query.page||1) + 1

                        this.$router.push({
                            name:this.modulePage,
                            query:_.clone(this.filter)
                        }).catch(()=>{})
                    }
                }
            }
            if (this.onScrollPoint && scrollEl.scrollTop == 0) {
                this.onScrollPoint = false
                window.removeEventListener('wheel', this.tableScrollFunction, false)
            }
        },
        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },
        checkAll(e){
            if(e){
                this.selectedFilter = [
                    'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
                ]
            }else{
                this.selectedFilter = []
            }
        },
        redirect(v){
            this.loadingOverlay=true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'duplicate-resep-dokter', id: v.ar_id, arm_id: v.arm_id}}, 
                "POST"
            ).then(()=>{
                this.loadingOverlay = false 
                this.$router.push({ name: 'Farmasi', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }).catch(()=>{})
                this.$socket.emit('refresh_data', {to : "Farmasi"})                       
            })
        },
        tableScrollFunction(e) {
           const responsiveTable = document.querySelector('.table-responsive')
           if(responsiveTable){
                let scrollDelta = e.deltaY
                let resScrollPos = responsiveTable.scrollTop + scrollDelta
                responsiveTable.scroll({
                    left: responsiveTable.scrollLeft,
                    top: resScrollPos,
                    behavior: 'smooth'
                })
            }
        },
        setSlide(){
            const responsiveTable = document.querySelector('.table-responsive')
            const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
            const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
            if(responsiveTableScroll){
                responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                Array.from(responsiveTableScrollActions).forEach(action => {
                    action.addEventListener('click', e => {
                        const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                         
                        responsiveTable.scroll({
                            left: scrollAmount,
                            behavior: 'smooth'
                        })
                    })
                })
            }
        },
        
        
        windowTableScroller(e){
            const responsiveTable = document.querySelector('.table-responsive')
            if(responsiveTable){
                                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                if(responsiveTableScroll){
                    responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                    this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                    const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                    if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                        this.onScrollPoint = true
                        document.documentElement.scroll(0, this.tableScrollPoint)
                        window.addEventListener('wheel', this.tableScrollFunction, false)
                    }
                }
            }
        },
    },
    mounted() {
        let filterColumn = JSON.parse(localStorage.getItem(`kolumn_farmasi_${this.user.id}`) || '[]')
        if(filterColumn.length){
            this.selectedFilter = filterColumn
            this.acceptedFilter = filterColumn
        }

        this.filter.page = 1
        this.filter.poli = 'ALL'
        
        if(this.$route.query.startDate && this.$route.query.endDate){
            this.dateRange.startDate = this.$route.query.startDate
            this.dateRange.endDate = this.$route.query.endDate
        }

        this.filter.process_status_farmasi = 'N'
        if(this.$route.query.page != 1){
            let url = {
                name:this.$route.name,
                params: (this.$route.params||{}),
                query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
            }
            this.$router.push(url).catch(()=>{})
        }else{
            this.apiGet(['list', 'master_dan_resume'])
        }
        
        setTimeout(()=>{
            this.setSlide()
        },1000)
        
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.addEventListener('scroll', this.handleScroll)
        
        window.addEventListener('scroll', this.windowTableScroller)
        this.onScrollPoint = false
    },  
    created () {
        // window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        // window.removeEventListener('scroll', this.handleScroll)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)
        
        window.removeEventListener('scroll', this.windowTableScroller)
    },
    watch:{
        $route(vNew, vOld){
           if (
						this.firstInitLoaded.indexOf('master') > -1 && 
						this.firstInitLoaded.indexOf('resume') > -1
					) {
						if (vNew.query.page > vOld.query.page) {
							this.apiGet(['list']) // table scroll = load list only
						} else {
								this.apiGet(['list', 'resume'])
						}
					} else {
						this.apiGet(['list', 'master_dan_resume']) // reload all
					}

          setTimeout(()=>{
                if(this.isList){
                    this.setSlide()
                }
            },1000)
        },
        'onScrollPoint'(v){
            document.body.style.overflow = v ? 'hidden' : 'auto'
        },
        'openPanggil'(v){
            if(!v && Object.keys(this.rowEdit||{}).length){
                setTimeout(()=>{
                    this.rowEdit.ar_is_called_ppa_farmasi = 'N'    
                },1000)
            }
        }   
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
  .report-field .vue-daterange-picker .reportrange-text{
    width: 312px !important;
  }
</style>