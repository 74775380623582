<template>
	<div class="">
		<div class="card">
			<div class="bg-white card-header">
				<div class="row align-items-center">
					<div class="col-md-4 col-lg-5">
						<div class="form-row">
							<div class="col-lg-4">
								<h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
							</div>

							<div class="col-lg-8" v-if="isshowTable('ar_reg_date')">
								<div class="form-group mb-0 d-flex">
									<date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
										:autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
										:ranges="datePickerConfig.ranges" @update="updateValues">
										<template v-slot:input="picker">
											{{ picker.startDate | date }} - {{ picker.endDate | date }}
										</template>
									</date-range-picker>
									<div class="input-group-append calendar-group">
										<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-8 col-lg-7">
						<div class="form-row justify-content-end">
							<div class="col-md">
								<div class="form-row justify-content-end">
									<!--
									<div class="col-md-6">
											<div class="lbl_status_pasien bg_red">
													<h3>
															<i class="icon-users2"></i>
															{{getQueue}} PASIEN ANTRI
													</h3>

											</div>
									</div>
									-->
                                    
                                    <div class="col-md-6">
                                        <div @click="doFilterKritis()" class="lbl_status_pasien bg_red" v-b-tooltip.hover :title="'Klik Untuk Memfilter Pasien Kritis'">
                                            <h3>
                                                <i class="icon-users2"></i>
                                                {{getKritis}} PASIEN KRITIS
                                            </h3>

                                        </div>
                                    </div>
									<div class="col-md-6">
										<div class="lbl_status_pasien bg_green">
											<h3>
												<i class="icon-user-check"></i>
												{{getAction}} DALAM TINDAKAN PPA Radiologi
											</h3>
										</div>
									</div>
								</div>
							</div>

							<div class="col-md-auto">
								<a href="javascript:;" @click="$parent.openModalResume(filter.startDate,filter.endDate)"
									data-toggle="modal" data-target="#FunnelData" class="lbl_status_pasien bg_blue">
									<h3><i class="icon-menu"></i></h3>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-header">
				<div class="row">
					<div class="col-md-8">
						<div class="row g-1">
							<div class="col-md-auto">
								<div class="form-group mb-0">
									<button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
										class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover><i class="icon-spinner11"></i></button>
								</div>
							</div>
							<div class="col-md-auto">
								<div class="form-group mb-0">
									<button @click="openFilter = true" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
										class="btn btn-light" title="Atur Kolom" v-b-tooltip.hover><i class="icon-table2"></i></button>
								</div>
							</div>
							<div class="col-md">
								<div class="row g-1">
									<div class="col-md-6 col-lg-4" v-if="isshowTable('mpo_name')">
										<b-form-group class="mb-0">
											<v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli"
												label="text" :clearable="true" :reduce="v=>v.value"></v-select>
										</b-form-group>
									</div>
		
									<div class="col-md-6 col-lg-4">
										<b-form-group>
											<v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.process_status"
												:options="Config.mr.configStatus" label="text" :reduce="v=>v.value"></v-select>
										</b-form-group>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
							<input class="form-control" v-model="filter.search" @input="doSearch"
								placeholder="Ketik Nama/No. RM Pasien" />
							<div class="form-control-feedback">
								<i class="icon-search4 text-indigo"></i>
							</div>

							<b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn"
								@click="doResetData()">Reset</b-button>
						</div>

					</div>
				</div>
			</div>
			<div class="table-responsive sticky-table">
				<table class="table table-bordered table-striped table-hover table-sm patient-table dashboard-table">
					<thead>
						<tr>

							<th>No.</th>
							<th>Panggil Pasien</th>
							<th v-if="isRegistration">No Antrean</th>
							<th v-if="isshowTable('ar_reg_code')">No. Kedatangan</th>
							<th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
							<th v-if="isshowTable('ar_status')">Status</th>
							<th v-if="isshowTable('mpo_name')">Nama Poli</th>
							<th v-if="isshowTable('ar_reg_date')">Tanggal Daftar</th>
							<th v-if="isshowTable('ap_code')">No. RM</th>
							<th v-if="isshowTable('bu_full_name')">Nama Dokter</th>
							<th v-if="isshowTable('mcp_name')">Cara Bayar</th>
							<th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
							<th v-if="isshowTable('ap_usia')">Usia</th>
							<th v-if="isshowTable('ap_gender')">Jenis Kelamin</th>
							<th v-if="isshowTable('ar_penunjang')">Penunjang</th>
							<th>Aksi</th>
						</tr>
					</thead>
					<tbody v-if="!loadingTable">
						<template v-for="(v,k) in dataReg">
							<tr :key="k"
								:class="(v.ar_process_status_ppa_radiologi == 'QUEUE' ? 'table-info' : v.ar_process_status_ppa_radiologi == 'VOID' ? 'table-danger' : '') || (v.ar_is_kritis_lab == 'Y' ? 'table-danger' : '') || (v.ar_is_kritis_radiologi == 'Y' ? 'table-danger' : '')">
								<td>{{k+1}}</td>

								<td class="text-center">
									<a v-if="v.ar_is_void !== 'Y' && v.ar_process_status !== 'ABSEN'"
										@click="requestPanggil(v,v.ar_is_called_ppa_radiologi)" href="javascript:;" data-toggle="modal"
										data-target="#Fasttrack" data-popup="tooltip" title="Panggil Pasien"
										class="btn btn-icon rounded-round btn-sm alpha-danger border-danger"
										data-original-title="Panggil Pasien"><i
											:class="v.ar_is_called_ppa_radiologi !== 'Y' && (user.levelId == 1 || user.levelId == 9 || user.levelId == 13 || user.levelId == 11 || user.levelId == 12 || user.levelId == 6) && v.ar_status == sPPA ? 'icon-megaphone' : 'icon-eye'"></i></a>
									<span v-else> - </span>

									<a v-if="(v.ar_status != 99 && v.ar_status != 98)" @click="showDataRadiologiNew(v)"
										href="javascript:;" data-toggle="modal" data-target="#Fasttrack" data-popup="tooltip"
										title="Lihat Tindakan Lab Dokter"
										class="ml-1 btn btn-icon rounded-round btn-sm alpha-danger border-danger"
										data-original-title="Panggil Pasien"><i :class="'icon-lab'"></i></a>
								</td>

								<td v-if="isRegistration">
									<small class=" font-weight-semibold"
										style="font-size: 18px;">{{v.ar_no_antrian_ppa_radiologi||"-"}}</small>
									<span class="badge bg-primary mb-1" v-if="v.ar_is_fast_track == 'Y'">FAST TRACK</span>
                                    
                                    <span v-if="v.ar_is_kritis_lab == 'Y' || v.ar_is_kritis_radiologi == 'Y'" class="badge bg-danger mb-1">Kritis </span>
								</td>

								<td v-if="isshowTable('ar_reg_code')">
									{{v.ar_reg_code||"-"}}
									<span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
									<span v-else class="text-danger">PASIEN LAMA</span> </td>

								<td v-if="isshowTable('ap_fullname')">
									<span>{{uppercaseWord(v.ap_fullname)||"-"}}</span>
									<p>{{v.ap_nik||"-"}}</p>
								</td>

								<td v-if="isshowTable('ar_status')">

									<template v-if="(v.ar_status <= sFisio) || (v.ar_status == 99 || v.ar_status == 98)">
										<span v-if="v.ar_process_status_ppa_radiologi == 'QUEUE' && v.ar_status == sPPA"
											:class="`badge bg-warning`">Dalam Antrean</span>

										<span v-else :class="`badge ${v.ars_color}`">{{uppercaseWord(v.ars_name)||'-'}}</span>
									</template>

									<template v-else>
										<template v-if="v.ar_status == sPPA">
											<span v-if="v.ar_is_ppa_fisio == 'Y' && v.ar_is_done_ppa_fisio !== 'Y'"
												:class="'badge bg-blue-600 text-white mb-1'">Fisioterapi</span>
											<span v-if="v.ar_is_ppa_radiologi == 'Y' && v.ar_is_done_ppa_radiologi !== 'Y'"
												:class="'badge bg-blue-600 text-white mb-1'">Radiologi</span>
											<span v-if="v.ar_is_ppa_lab == 'Y' && v.ar_is_done_ppa_lab !== 'Y'"
												:class="'badge bg-blue-600 text-white mb-1'">Laboratorium</span>
											<span v-if="v.ar_is_ppa_dietisien == 'Y' && v.ar_is_done_ppa_dietisien !== 'Y'"
												:class="'badge bg-info text-white mb-1'">Dietisien</span>
										</template>
										<span v-else :class="`badge ${v.ars_color}`">{{uppercaseWord(v.ars_name)||'-'}}</span>
									</template>

									<span v-if="v.ar_is_belum_datang == 'Y'"
										:class="`badge badge-info mt-1`">{{uppercaseWord('Belum Datang')||'-'}}</span>

									<span v-if="v.arm_is_draft == 'Y'"
										:class="`badge badge-warning mt-1`">{{uppercaseWord('Draft')||'-'}}</span>

									<span v-if="v.ar_tanggal_ranap" :class="`badge badge-info mt-1`">Pasien Rawat Inap</span>

									<span v-if="v.ar_status == 99 || v.ar_status == 98" :class="`text-danger font-weight-semibold mt-1`">
										Diabsen Oleh : {{v.absen_by||"-"}}</span>
								</td>

								<td v-if="isshowTable('mpo_name')">{{uppercaseWord(v.mpo_name)||"-"}}</td>
								<td v-if="isshowTable('ar_reg_date')">
									{{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>
								<td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">
									{{uppercaseWord(v.ap_code)||"-"}}</td>
								<td v-if="isshowTable('bu_full_name')">{{uppercaseWord(v.bu_full_name)||"-"}}</td>
								<td v-if="isshowTable('mcp_name')">{{uppercaseWord(v.mcp_name)||"-"}}</td>
								<td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
								<td v-if="isshowTable('ap_usia')">
									{{v.ap_usia_with_ket||"-"}}
									<span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
								</td>
								<td v-if="isshowTable('ap_gender')">{{getGender(v.ap_gender)||"-"}}</td>
								<td v-if="isshowTable('ar_penunjang')">
									<div v-if="v.ar_is_ppa_radiologi == 'Y'" class="d-flex align-items-center">
										<i class="icon-checkmark-circle text-success"></i>
										<span class="ml-1">Radio</span>
									</div>
									<div v-if="v.ar_is_ppa_lab == 'Y'" class="d-flex align-items-center">
										<i class="icon-checkmark-circle text-success"></i>
										<span class="ml-1">Lab</span>
									</div>
									<div v-if="v.ar_is_ppa_fisio == 'Y'" class="d-flex align-items-center">
										<i class="icon-checkmark-circle text-success"></i>
										<span class="ml-1">Fisioterapi</span>
									</div>
									<div v-if="v.ar_is_ppa_dietisien == 'Y'" class="d-flex align-items-center">
										<i class="icon-checkmark-circle text-success"></i>
										<span class="ml-1">Dietisien</span>
									</div>
									<span
										v-if="v.ar_is_ppa_radiologi !== 'Y' && v.ar_is_ppa_lab !== 'Y' && v.ar_is_ppa_fisio !== 'Y' && v.ar_is_ppa_dietisie !== 'Y'">
										- </span>
								</td>
								<td>
									<div :id="`isAction${v.ar_id}`">

										<a href="javascript:;" class="btn alpha-info border-info text-info-800 btn-icon rounded-round"
											data-popup="tooltip" v-b-tooltip.hover title="Ajukan Perubahan Data" @click="requestEdit(v)"
											v-if="moduleRole('request_edit') && v.arm_id && !v.arm_can_edit_by && !otherConditional(v)"><i
												class="icon-drag-left"></i></a>

										<a href="javascript:;" @click="redirect(v)"
											class="btn alpha-success border-success text-success-800 btn-icon rounded-round"
											data-popup="tooltip" v-b-tooltip.hover title="Edit"
											v-if="(v.arm_can_edit_by && (v.arm_is_approve == 'Y' && v.arm_is_approve_upla == 'Y') || otherConditional(v)) && v.arm_id"><i
												class="icon-pencil7"></i></a>
									</div>



									<template v-if="isPoliAdj">
										<a href="javascript:;" v-if="(v.ar_status != 99 && v.ar_status != 98)"
											@click="changeJadwalRadiologiNew(v)"
											class="btn alpha-success border-success text-success-800 btn-icon rounded-round"
											data-popup="tooltip" v-b-tooltip.hover.right title="Ubah jadwal"><i class="icon-history"></i></a>
									</template>
									<template v-else>
										<a href="javascript:;" v-if="v.ar_process_status_ppa_radiologi == 'QUEUE' && v.ar_status == sPPA"
											@click="changeJadwalRadiologi(v)"
											class="btn alpha-success border-success text-success-800 btn-icon rounded-round"
											data-popup="tooltip" v-b-tooltip.hover.right title="Ubah jadwal"><i class="icon-history"></i></a>
									</template>


									<span v-if="v.arm_id && !v.arm_can_edit_by && !otherConditional(v)"> - </span>
								</td>
							</tr>
						</template>
					</tbody>
					<tbody v-if="loadingTable">
						<tr>
							<td colspan="99">
								<div class="skeletal-comp"></div>
							</td>
						</tr>
						<tr>
							<td colspan="99">
								<div class="skeletal-comp"></div>
							</td>
						</tr>
						<tr>
							<td colspan="99">
								<div class="skeletal-comp"></div>
							</td>
						</tr>
					</tbody>
					<tbody v-if="loadingInvinite">
						<tr>
							<td colspan="99">
								<div class="skeletal-comp"></div>
							</td>
						</tr>
					</tbody>
					<tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">
						<tr>
							<th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
								Data Tidak Ditemukan
							</th>
						</tr>
					</tbody>
				</table>
				<div
					:class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
					<a href="javascript:;" data-scroll="left"
						class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
						<i class="icon-chevron-left"></i>
					</a>
					<a href="javascript:;" data-scroll="right"
						class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
						<i class="icon-chevron-right"></i>
					</a>
				</div>
			</div>
		</div>

		<b-modal v-model="openFilter" :title="'Filter Kolom'" size="sm" ok-title="Terapkan" @ok="terapkan()">
			<div class="row">
				<div class="col-md-12">
					<div class="wrap_line">
						<div class="form-check p-0">
							<label class="form-check-label d-flex">
								<b-form-checkbox @input="checkAll($event)" v-model="selectedAll" class="form-check-input-styled"
									name="radio-inline-left" />
								Tampilkan Semua
							</label>
						</div>
						<hr class="my-2">
						<div class="pl-2">
							<b-form-group>
								<b-form-checkbox-group id="checkbox-block" v-model="selectedFilter" :options="optionFilter"
									name="filter"></b-form-checkbox-group>
							</b-form-group>
						</div>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal v-model="openPersetujuan" :title="'Konfirmasi Ajukan Perubahan'" size="sm" body-class="p-2" hide-footer>
			<div class="modal-body">
				<div class="text-center">
					<div class="modal-shout-icon mb-3 d-inline-block alpha-warning text-warning rounded-circle">
						<i class="icon-warning2"></i>
					</div>
					<h6 class="mb-3">Apakah Anda yakin untuk mengajukan perubahan pengkajian untuk pasien
						{{rowEdit.ap_fullname || "-"}} ?</h6>
				</div>
			</div>

			<div class="modal-footer">
				<button type="button" @click="openPersetujuan = false" class="btn btn-link" data-dismiss="modal">Batal</button>
				<button type="submit" @click="konfirmasiEdit" class="btn btn-success btn-labeled btn-labeled-left"><b><i
							class="icon-checkmark"></i></b> Ya, Ajukan</button>
			</div>
		</b-modal>

		<b-modal v-model="openPanggil" :title="'Konfirmasi Pemanggilan Pasien'" size="md" body-class="p-2" header-class="bg-primary" hide-footer>
			<div class="modal-body">
				<div class="text-center">
					<div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
						<i class="icon-megaphone"></i>
					</div>
					<h6 class="mb-0">Memanggil Pasien dengan No. Antrian {{rowEdit.ar_no_antrian_ppa_radiologi}} atas Nama</h6>
					<h3 class="font-weight-semibold text-primary">{{rowEdit.ap_fullname||"-"}}</h3>

					<div class="mt-1 mb-3">
						<a href="javascript:;" v-if="isRegistration && !isPanggil" @click="canConfirm=true;panggilAntrean(rowEdit)"
							class="btn btn-info btn-labeled btn-labeled-left mr-1">
							<b><i class="icon-megaphone"></i></b>
							Panggil Antrean
						</a>

						<a href="javascript:;" v-else-if="isRegistration && isPanggil"
							class=" btn btn-secondary btn-labeled btn-labeled-left mr-1">
							<b><i class="icon-megaphone"></i></b>
							Memanggil ...
						</a>
					</div>
					<template>
						<h6>Apakah pasien hadir?</h6>
						<b-form-group class="mt-3">
							<b-form-radio-group :options="Config.mr.StatusPanggil" v-model="rowEdit.ar_is_called_ppa_radiologi" />
						</b-form-group>
						<div class="mt-4">
							<a href="javascript:;" @click="konfirmasiPanggil()" class="btn btn-success btn-labeled btn-labeled-left">
								<b><i class="icon-checkmark"></i></b>
								Konfirmasi
							</a>
						</div>
					</template>
				</div>
			</div>
		</b-modal>

		<b-modal v-model="detailData" :title="'Data Tindakan Radiologi'" size="lg" body-class="p-2" hide-footer>
			<div class="modal-body">
				<div class="row g-3">
					<template v-for="(v,k) in rowDetail.appdr_data">
						<div class="col-md-4 col-lg-3" :key="k+'radioData'" v-if="isShowHeadRadiologi(k)">
							<div class="card shadow-0 border">
								<div class="card-header py-2 bg-light">
									<h6 class="card-title text-uppercase font-weight-semibold">{{
                    getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
								</div>
								<div class="card-body py-2">
									<template v-for="v1,k1 in (v.data||[])">
										<div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
											<i class="icon-checkmark-circle text-success align-middle mr-1"></i>
											<span class="align-middle font-weight-semibold">{{v1.text||"-"}}</span>
											<span v-if="v1.notes">, {{v1.notes}}</span>
											<div v-if="isPoliAdj">
												<small class="" v-if="v1.tanggal">
													<b>Tangal Periksa : </b>{{v1.tanggal | moment("DD MMMM YYYY")}}</small>
											</div>
										</div>
									</template>
								</div>
							</div>
						</div>
					</template>
					<div class="col-md-4 col-lg-3" v-if="rowDetail.appdr_hasil_lainnya">
						<div class="card shadow-0 border">
							<div class="card-header py-2 bg-light">
								<h6 class="card-title text-uppercase font-weight-semibold">Item
									Pemeriksaan Lainnya</h6>
							</div>
							<div class="card-body py-2">
								<div class="req-list">
									<div>
										<i class="icon-checkmark-circle text-success align-middle mr-1"></i>
										<span class="align-middle">{{rowDetail.appdr_hasil_lainnya||"-"}}</span>
										<div v-if="isPoliAdj">
											<small class="" v-if="rowDetail.appdr_hasil_lainnya_date">
												<b>Tangal Periksa : </b>{{rowDetail.appdr_hasil_lainnya_date | moment("DD MMMM YYYY")}}</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<span v-if="!selectedRadioInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
				</div>
			</div>
		</b-modal>

		<validation-observer ref="VFormJadwal">
			<b-modal v-model="changeJadwal" :title="'Ubah Jadwal Radiologi'" size="sm" body-class="p-2"
				@ok.prevent="submitJadwalBaru">
				<div class="modal-body">
					<div class="row g-3">
						<div class="col-md-12">
							<b>Jadwal Sebelumnya : {{rowEdit.ar_reg_ppa_radiologi | moment("DD MMMM YYYY")}}</b>
						</div>

						<div class="col-md-12">
							<div class="form-group">
								<div class="input-group">
									<datepicker input-class="form-control transparent" :disabledDates="disabledDates"
										placeholder="Pilih Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar"
										v-model="rowEdit.ar_reg_ppa_radiologi_new">
									</datepicker>
									<div class="input-group-append calendar-group">
										<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
									</div>
								</div>
								<VValidate name="Pilih Tanggal" v-model="rowEdit.ar_reg_ppa_radiologi_new" :rules="{required : 1}" />
							</div>
						</div>
					</div>
				</div>
			</b-modal>
		</validation-observer>

		<!-- ada adustment -->
		<validation-observer ref="VFormJadwalNew">
			<b-modal v-model="changeJadwalNew" :title="'Ubah Jadwal Radiologi'" size="xl" body-class="p-2"
				@ok.prevent="submitJadwalBaruNew">
				<div class="modal-body">
					<template v-if="(dataListDokter||[]).length">
						<ul class="nav nav-tabs nav-tabs-bottom nav-justified mt-2 mb-0"
							v-if="(dataListDokter[0]['appdr_data']).length">
							<li v-for="(v,k) in (dataListDokter||[])" :key="k" class="nav-item"><a href="javascript:;"
									@click="changeTab(k)" :class="tabDetail == k ? 'nav-link active' : 'nav-link'"
									data-toggle="tab">Pemeriksaan Oleh : {{v.bu_full_name}}, Tanggal :
									{{v.arm_submit_date | moment("DD MMMM YYYY")}}</a></li>
						</ul>
					</template>
					<div class="row g-3 mt-3">
						<template v-for="(v,k) in rowDetail.appdr_data">
							<div class="col-md-4 col-lg-3" :key="k+'radioData'" v-if="isShowHeadRadiologiV2(k)">
								<div class="card shadow-0 border">
									<div class="card-header py-2 bg-light">
										<h6 class="card-title text-uppercase font-weight-semibold">{{
                        getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
									</div>
									<div class="card-body py-2">
										<template v-for="v1,k1 in (v.data||[])">
											<div v-if="isShowRadiologiV2(k,k1)" :key="k1+'radiosubdatas'">
												<i class="icon-checkmark-circle text-success align-middle mr-1"></i>
												<span class="align-middle font-weight-semibold">{{v1.text||"-"}}</span>
												<span v-if="v1.notes">, {{v1.notes}}</span>
												<template v-if="isPoliAdj && v1.tanggal">
													<div class="mb-3">
														<label class="mt-1 mb-1" for="">Tanggal Pemeriksaan</label>
														<div class="input-group">
															<datepicker :disabled="v1.status == 'Y'" input-class="form-control transparent"
																placeholder="Tanggal Pemeriksaan" class="my-datepicker"
																calendar-class="my-datepicker_calendar" v-model="v1.tanggal">
															</datepicker>
															<div class="input-group-append calendar-group">
																<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
															</div>
														</div>
													</div>
												</template>
											</div>
										</template>
									</div>
								</div>
							</div>
						</template>
						<div class="col-md-4 col-lg-3" v-if="rowDetail.appdr_hasil_lainnya && rowDetail.lainnya_show">
							<div class="card shadow-0 border">
								<div class="card-header py-2 bg-light">
									<h6 class="card-title text-uppercase font-weight-semibold">Item
										Pemeriksaan Lainnya</h6>
								</div>
								<div class="card-body py-2">
									<div class="req-list">
										<div>
											<i class="icon-checkmark-circle text-success align-middle mr-1"></i>
											<span class="align-middle">{{rowDetail.appdr_hasil_lainnya||"-"}}</span>
											<template v-if="isPoliAdj && rowDetail.appdr_hasil_lainnya_date">
												<div class="mb-3">
													<label class="mt-1 mb-1" for="">Tanggal Pemeriksaan</label>
													<div class="input-group">
														<datepicker :disabled="rowDetail.appdr_hasil_lainnya_status == 'Y'"
															input-class="form-control transparent" placeholder="Tanggal Pemeriksaan"
															class="my-datepicker" calendar-class="my-datepicker_calendar"
															v-model="rowDetail.appdr_hasil_lainnya_date">
														</datepicker>
														<div class="input-group-append calendar-group">
															<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
														</div>
													</div>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
						</div>
						<span v-if="!selectedRadioInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
					</div>
				</div>
			</b-modal>
		</validation-observer>
		<!-- ada adustment -->

		<b-modal v-model="detailDataNew" :title="'Data Tindakan Radiologi'" size="xl" body-class="p-2" hide-footer>
			<div class="modal-body">
				<template v-if="(dataListDokter||[]).length">
					<ul class="nav nav-tabs nav-tabs-bottom nav-justified mt-2 mb-0"
						v-if="(dataListDokter[0]['appdr_data']).length">
						<li v-for="(v,k) in (dataListDokter||[])" :key="k" class="nav-item"><a href="javascript:;"
								@click="changeTab(k)" :class="tabDetail == k ? 'nav-link active' : 'nav-link'"
								data-toggle="tab">Pemeriksaan Oleh : {{v.bu_full_name}}, Tanggal :
								{{v.arm_submit_date | moment("DD MMMM YYYY")}}</a></li>
					</ul>
				</template>

				<div class="row g-3 mt-3">
					<template v-for="(v,k) in rowDetail.appdr_data">
						<div class="col-md-4 col-lg-3" :key="k+'radioData'" v-if="isShowHeadRadiologi(k)">
							<div class="card shadow-0 border">
								<div class="card-header py-2 bg-light">
									<h6 class="card-title text-uppercase font-weight-semibold">{{
                    getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
								</div>
								<div class="card-body py-2">
									<template v-for="v1,k1 in (v.data||[])">
										<div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
											<i class="icon-checkmark-circle text-success align-middle mr-1"></i>
											<span class="align-middle font-weight-semibold">{{v1.text||"-"}}</span>
											<span v-if="v1.notes">, {{v1.notes}}</span>
											<div v-if="isPoliAdj">
												<small class="" v-if="v1.tanggal">
													<b>Tangal Periksa : </b>{{v1.tanggal | moment("DD MMMM YYYY")}}</small>
											</div>
										</div>
									</template>
								</div>
							</div>
						</div>
					</template>
					<div class="col-md-4 col-lg-3" v-if="rowDetail.appdr_hasil_lainnya">
						<div class="card shadow-0 border">
							<div class="card-header py-2 bg-light">
								<h6 class="card-title text-uppercase font-weight-semibold">Item
									Pemeriksaan Lainnya</h6>
							</div>
							<div class="card-body py-2">
								<div class="req-list">
									<div>
										<i class="icon-checkmark-circle text-success align-middle mr-1"></i>
										<span class="align-middle">{{rowDetail.appdr_hasil_lainnya||"-"}}</span>
										<div v-if="isPoliAdj">
											<small class="" v-if="rowDetail.appdr_hasil_lainnya_date">
												<b>Tangal Periksa : </b>{{rowDetail.appdr_hasil_lainnya_date | moment("DD MMMM YYYY")}}</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<span v-if="!selectedRadioInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
				</div>
			</div>
		</b-modal>


	</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'
import Datepicker from 'vuejs-datepicker'

export default {
    extends: GlobalVue,
    data() {
        return {
            mPoli: [],
            dataReg: [],
            rowEdit: {},
            loadingInvinite: false,
            openPersetujuan: false,
            openPanggil: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            openFilter: false,
            getQueue: 0,
            getAction: 0,
            getKritis: 0,
            onScrollPoint: false,
            tableScrollPoint: null,
            canConfirm: false,

			firstInitLoaded: [],
            
            optionFilter: [
                { text: 'No. Kedatangan', value: 'ar_reg_code' },
                { text: 'Nama', value: 'ap_fullname' },
                { text: 'Status', value: 'ar_status' },
                { text: 'Nama Poli', value: 'mpo_name' },
                { text: 'Tanggal Daftar', value: 'ar_reg_date' },
                { text: 'Tanggal Pemeriksaan', value: 'ar_reg_ppa_lab' },
                { text: 'No RM.', value: 'ap_code' },
                { text: 'Nama Dokter', value: 'bu_full_name' },
                { text: 'Payor', value: 'mcp_name' },
                { text: 'Tanggal Lahir', value: 'ap_dob' },
                { text: 'Usia', value: 'ap_usia' },
                { text: 'Jenis Kelamin', value: 'ap_gender' },
                { text: 'Penunjang', value: 'ar_penunjang' },
            ],
            selectedFilter: [
                'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
            ],
            acceptedFilter: [
                'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            },
            isPanggil: false,

            openVerifikasiRadiologi: false,
            detailData: false,
            rowDetail: {},
            changeJadwal: false,
            // ada adjusment //
            changeJadwalNew: false,
            dataListDokter: [],
            tabDetail: 0,
            regId: null,
            detailDataNew: false,
            // ada adjusment //
        }
    },
    computed: {    
        
        disabledDates() {
            return {
                to: new Date(moment().format('YYYY-MM-DD'))
            }
        },   

        countSkrining(){
            let total = 0
            for(let i = 0; i < (this.dataGizi.asg_data||[]).length; i++){
                if(this.dataGizi.asg_data[i]['value']){
                    total += 1
                }
            }
            return total
        },
        
        selectedRadioInput() {
            let data = []
            for (let i = 0; i < (this.rowDetail.appdr_data || []).length; i++) {
                for (let j = 0; j < (this.rowDetail.appdr_data[i]['data'] || []).length; j++) {
                    if (this.rowDetail.appdr_data[i]['data'][j]['selected']) {
                    data.push(this.rowDetail.appdr_data[i]['data'][j]['text'])
                    }
                }
            }
            if (this.rowDetail.appdr_hasil_lainnya) {
                data.push(this.rowDetail.appdr_hasil_lainnya)
            }
            return data.join(", ")
        },
    },
  	components:{DateRangePicker,Datepicker},
    methods: {
        doFilterKritis(){
            this.filter.process_status = "ALL"
            this.filter.kritis = "Y"
            this.doFilter()
        },
        tindakanPerItem(rowDetail){
            let data = []
            if(rowDetail.appdr_to_radio == "Y"){
                for (let i = 0; i < (rowDetail.appdr_data || []).length; i++) {
                    for (let j = 0; j < (rowDetail.appdr_data[i]['data'] || []).length; j++) {
                        if (rowDetail.appdr_data[i]['data'][j]['selected']) {
                        
                        data.push({
                            id : rowDetail.appdr_data[i]['data'][j]['id'],
                            tindakan : rowDetail.appdr_data[i]['data'][j]['text'],
                            date : moment(rowDetail.appdr_data[i]['data'][j]['tanggal']).format('YYYY-MM-DD'),
                            type : "RADIO",
                            status : rowDetail.appdr_data[i]['data'][j]['status'] || "N" // belum terdaftar
                        })
                        }
                    }
                }
                    
                if (rowDetail.appdr_hasil_lainnya) {
                    data.push({
                        id : 99999, //lainnya
                        tindakan : rowDetail.appdr_hasil_lainnya,
                        date : moment(rowDetail.appdr_hasil_lainnya_date).format('YYYY-MM-DD'),
                        type : "RADIO",
                        status : rowDetail.appdr_hasil_lainnya_status // belum terdaftar
                    })
                }
            }
            
            for (let ik = 0; ik < (rowDetail.appdl_data || []).length; ik++) {
            for (let jk = 0; jk < (rowDetail.appdl_data[ik]['dubData'] || []).length; jk++) {
                for (let kk = 0; kk < (rowDetail.appdl_data[ik]['dubData'][jk]['data'] || []).length; kk++) {
                if (rowDetail.appdl_data[ik]['dubData'][jk]['data'][kk]['selected']) {
                    data.push({
                    id : rowDetail.appdl_data[ik]['dubData'][jk]['data'][kk]['id'],
                    tindakan : rowDetail.appdl_data[ik]['dubData'][jk]['data'][kk]['text'],
                    date : moment(rowDetail.appdl_data[ik]['dubData'][jk]['data'][kk]['tanggal']).format('YYYY-MM-DD'),
                    type : "LAB",
                    status : rowDetail.appdl_data[ik]['dubData'][jk]['data'][kk]['status'] || "N" // belum terdaftar
                    })
                }
                }
            }
            }
            if (rowDetail.appdl_hasil_lainnya) {
            data.push({
                id : 99999, //lainnya
                tindakan : rowDetail.appdl_hasil_lainnya,
                date : moment(
                    rowDetail.appdl_hasil_lainnya_date).format('YYYY-MM-DD'),
                type : "LAB",
                status : rowDetail.appdl_hasil_lainnya_status // belum terdaftar
            })
            }
            return data
        },
        changeTab(i){
            this.tabDetail = i
            this.rowDetail = this.dataListDokter[i]  
        },
        // ada adjustment
        submitJadwalBaruNew(){
            this.$refs['VFormJadwalNew'].validate().then(success=>{
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: "Apakah Anda yakin untuk mengubah tanggal pemeriksaan radiologi Pasien?", // rewording
                        text: "Jika Ya, selanjutnya Pasien harus melakukan pendaftaran penunjang untuk bisa melakukan pemeriksaan ini.",
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if(result.value){
                            let data = {}                            
                            data.type = "change-date-radiologi-new"
                    
                            let dataListPost = this.dataListDokter
                            for(let i = 0; i < (dataListPost||[]).length; i++){
                                dataListPost[i].ar_penunjang_data = this.tindakanPerItem(dataListPost[i])
                                let penunjangData = dataListPost[i].ar_penunjang_data

                                let isRadio = penunjangData.find(entry => entry.date == moment().format('YYYY-MM-DD') && entry.type == 'RADIO') && dataListPost[i].appdr_to_radio == "Y"
                                isRadio = penunjangData.find(entry => entry.status == "Y" && entry.type == 'RADIO') && dataListPost[i].appdr_to_radio == "Y"

                                let isLab = penunjangData.find(entry => entry.date == moment().format('YYYY-MM-DD') && entry.type == 'LAB')
                                isLab = penunjangData.find(entry => entry.status == "Y" && entry.type == 'LAB')
                                
                                dataListPost[i].to_radio = isRadio ? "Y" : "N"
                                dataListPost[i].to_lab = isLab ? "Y" : "N"
                                dataListPost[i].appdr_penunjang_date = isRadio ? moment().format('YYYY-MM-DD') : null
                                dataListPost[i].appdl_penunjang_date = isLab ? moment().format('YYYY-MM-DD') : null
                            }

                            data.dataList = dataListPost
                            data.ar_id = this.regId

                            this.loadingOverlay = true   
                            Gen.apiRest(
                                "/do/"+this.modulePage,
                                {data: data}, 
                                "POST"
                            ).then(res=>{
                                this.$swal({
                                    title: `Jadwal Radiologi Berhasil Diganti`,
                                    icon: 'success',
                                })
                                this.loadingOverlay = false
                                this.changeJadwalNew = false
                                this.apiGet(['list', 'resume'])
                            })
                        }
                    })
                }
            })
        },  
        submitJadwalBaru(){
            this.$refs['VFormJadwal'].validate().then(success=>{
                if(success){
                    let data = this.rowEdit
                    data.type = "change-date-radiologi"
                    this.loadingOverlay = true   

                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data: data}, 
                        "POST"
                    ).then(res=>{
                        this.$swal({
                            title: `Jadwal Radiologi Berhasil Diganti`,
                            icon: 'success',
                        })
                        this.loadingOverlay = false
                        this.changeJadwal = false
                        this.apiGet(['list', 'resume'])
                    })

                }
            })
        },  
        isShowHeadRadiologi(j) {
            let isData = 0
            console.log(this.rowDetail.appdr_data[j])
            for (let k = 0; k < (this.rowDetail.appdr_data[j]['data'] || []).length; k++) {
                if (this.rowDetail.appdr_data[j]['data'][k]['selected']) {
                    isData += 1
                }
            }
            return isData
        },
        isShowRadiologi(j,k){
          let isData = 0
          if(this.rowDetail.appdr_data[j]['data'][k]['selected']){
            isData += 1
          }
          return isData
        },
        
        isShowHeadRadiologiV2(j) {
            let isData = 0
            for (let k = 0; k < (this.rowDetail.appdr_data[j]['data'] || []).length; k++) {
                if (this.rowDetail.appdr_data[j]['data'][k]['selected'] && this.rowDetail.appdr_data[j]['data'][k]['show']) {
                    isData += 1
                }
            }
            return isData
        },
        isShowRadiologiV2(j,k){
          let isData = 0
          if(this.rowDetail.appdr_data[j]['data'][k]['selected'] && this.rowDetail.appdr_data[j]['data'][k]['show']){
            isData += 1
          }
          return isData
        },

        showDataRadiologi(v){
            this.loadingOverlay = true    
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'detail-radiologi', id: v.ar_id}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false
                this.rowDetail = res.data.dataDokter      
                
                console.log(this.rowDetail.appdr_data)

                this.detailData = true
            })
        },
        
        showDataRadiologiNew(v){
            this.loadingOverlay = true    
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'detail-radiologi-new', id: v.ar_id, is_penunjang: v.ar_is_reg_penunjang}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false
                this.rowDetail = res.data.dataDokter[0]               
                this.dataListDokter = res.data.dataDokter         
                this.regId = v.ar_id
                this.detailDataNew = true
            })
        },

        test(data){
            this.$socket.emit('refresh_data', {to : this.uRadiologi})
            this.$socket.emit('refresh_data', {to : 'ALLPJ'})
        },
        panggilAntrean(data){
            // to specific user
            data.to = this.uRadiologi
            data.from = this.uRadiologi
            this.$socket.emit('panggil_pasien', data)

            // to all pj
            data.to = "ALLPJ"
            data.from = this.uRadiologi
            this.$socket.emit('panggil_pasien', data)
            
            this.isPanggil = true            
            setTimeout(()=>{
                this.isPanggil = false
            },5000)
        },
        doRefreshData(){
            this.apiGet(['list', 'master_dan_resume'], true)
            window.scrollTo(0,0)
        },
        otherConditional(v){
            return v.arm_created_by == this.user.id && moment().format('YYYY-MM-DD') <= this.to24Hours(v.arm_created_date) && v.ar_status != 7 
        },
        to24Hours(date){
            return moment(date).add(48, 'hours').format('YYYY-MM-DD')
        },

        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        terapkan(){
            if(!this.selectedFilter.length){
                return this.$swal({
                    icon: 'error',
                    title: 'Minimal harus ada 1 yang dipilih'
                })
            }
            this.acceptedFilter = this.selectedFilter
            localStorage.setItem(`kolumn_radiologi_${this.user.id}`,JSON.stringify(this.acceptedFilter))
			this.apiGet(['list', 'master_dan_resume'])
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },500),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },

        apiGet(loadApis = ['list', 'master', 'resume'], isReloadTable = false){
            let paramsQuery = Object.keys(this.$route.query||{}).length ? this.apiParams : this.$route.query
			
            this.doConvertDate()

            if((+this.$route.query.page||1) == 1 || isReloadTable){
                this.loadingTable = true
            }

			if (loadApis.indexOf('list') > -1) {
				this.apiGetList(paramsQuery)
			}

			if (loadApis.indexOf('master_dan_resume') > -1) {
				this.apiGetMasterDanAntrian(paramsQuery)
			} else {
				if (loadApis.indexOf('master') > -1) {
					this.apiGetMaster()
				}

				if (loadApis.indexOf('resume') > -1) {
					this.apiGetAntrian(paramsQuery)
				}
			}
        },
        apiGetList(paramsQuery) {
			Gen.apiRest(
                "/get/"+this.modulePage+
                '/ppaRadiologi', 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.user.levelId == 1 ? this.uRadiologi : null 
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data
                this.loadingTable = false
                this.loadingInvinite = false
 
                if((+this.$route.query.page||1) !== 1){
                    let data = resp.dataReg.data
                    for(let i = 0; i < (data||[]).length; i++){
                        this.dataReg.push(data[i])
                    }

                    if (data.length === 0) {
                        this.totalData = this.dataReg.length
                    } else {
                        this.totalData = resp.dataReg.total
                    }
                }else{
                    this.totalData = resp.dataReg.total
                    this.dataReg = resp.dataReg.data
                }   
            })
		},
		apiGetMasterDanAntrian(paramsQuery) {
			// get master & total antrian/tindakan
			Gen.apiRest(
				"/get/"+this.modulePage+
				'/getMasterDanAntrian', 
				{
					params: Object.assign({
						selectedFilter: this.selectedFilter.join(","),
						page : this.filter.page,
						startDate : this.filter.startDate,
						endDate : this.filter.endDate,
						byPassLevel: this.user.levelId == 1 ? this.uRadiologi : null 
					}, paramsQuery ||{})
				}
			).then(res=>{
				let resp = res.data                

				this.mPoli = resp.restMaster.mPoli
				this.mDokter = resp.restMaster.mDokter
				this.mAlatBantu = resp.restMaster.mAlatBantu

				this.getQueue = resp.restAntrian.getQueue
				this.getAction = resp.restAntrian.getAction
                this.getKritis = resp.restAntrian.getKritis

				this.firstInitLoaded = ['master', 'resume']
			})
		},
		apiGetMaster() {
			// get data master
			Gen.apiRest(
				"/get/" + this.modulePage +
				'/getMaster'
			).then(res => {
				let resp = res.data
				
				this.mPoli = resp.mPoli
				this.mDokter = resp.mDokter
				this.mAlatBantu = resp.mAlatBantu

				this.firstInitLoaded.push('master')
			})
		},
		apiGetAntrian(paramsQuery) {
			// get total antrian/tindakan
			Gen.apiRest(
				"/get/"+this.modulePage+
				'/getAntrian', 
				{
					params: Object.assign({
						selectedFilter: this.selectedFilter.join(","),
						page : this.filter.page,
						startDate : this.filter.startDate,
						endDate : this.filter.endDate,
						byPassLevel: this.user.levelId == 1 ? this.uFisio : null 
					}, paramsQuery ||{})
				}
			).then(res=>{
				let resp = res.data          

				this.getQueue = resp.getQueue
				this.getAction = resp.getAction
                this.getKritis = resp.getKritis

				this.firstInitLoaded.push('resume')
			})
		},
        handleScroll(event){
            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')

            if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    if(this.modulePage == 'Dashboard'){
                        this.loadingInvinite = true
                        this.filter.page = (+this.$route.query.page||1) + 1

                        this.$router.push({
                            name:this.modulePage,
                            query:_.clone(this.filter)
                        }).catch(()=>{})
                    }
                }
            }
            if (this.onScrollPoint && scrollEl.scrollTop == 0) {
                this.onScrollPoint = false
                window.removeEventListener('wheel', this.tableScrollFunction, false)
            }
        },
        requestEdit(v){
            this.rowEdit = v
            this.openPersetujuan = true
        },
        konfirmasiEdit(){
            this.loadingOverlay=true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'send-request', id: this.rowEdit.arm_id, arm_can_edit_by: this.user.id}}, 
                "POST"
            ).then(()=>{
                this.loadingOverlay = false 
                this.$swal({
                    title: `Permintaan Berhasil Dikirim`,
                    icon: 'success',
                })
                
                if((+this.$route.query.page||1) != 1){
                    this.doResetData()
                }else{
                    this.apiGet(['list', 'resume'])
                }
                this.openPersetujuan = false
                    
            }).catch(err=>{ 
                this.loadingOverlay = false 
                this.openPersetujuan = false
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
            })
        },
        
        requestPanggil(v,isCalled){
            this.rowEdit = v
            this.rowEdit.ar_is_called_ppa_radiologi = 'Y'
            if(isCalled == 'N'){
                if(this.user.levelId == 3 && v.ar_status == this.sPerawat){
                    this.openPanggil = true
                }else if(this.user.levelId == 5 && v.ar_status == this.sDokter){
                    this.openPanggil = true
                }else if(this.user.levelId == 7 && v.ar_status == this.sFisio){
                    this.openPanggil = true
                }else if((this.user.levelId == 9 || this.user.levelId == 13 || this.user.levelId == 11 || this.user.levelId == 12 || this.user.levelId == 6) && v.ar_status == this.sPPA){
                    this.openPanggil = true
                }else if((this.user.levelId == 1 || this.user.levelId == 13 || this.user.levelId == 11 || this.user.levelId == 12 || this.user.levelId == 6) && v.ar_status == this.sPPA){
                    this.openPanggil = true
                }else{
                    if(this.user.levelId !== 1){
                        this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }).catch(()=>{})
                    }else{
                        this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id, byPassLevel: this.user.levelId == 1 ? this.uRadiologi : null } }).catch(()=>{})
                    }
                }
            }else{
                if(this.user.levelId !== 1){
                    this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }).catch(()=>{})
                }else{
                    this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id, byPassLevel: this.user.levelId == 1 ? this.uRadiologi : null } }).catch(()=>{})
                }
            }
        },
        konfirmasiPanggil(){
            if(this.rowEdit.ar_is_called_ppa_radiologi == 'N'){
                this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin Pasien Tidak Hadir?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if (result.value) {
                        this.konfirmasiPanggilPostData()
                    }
                })
            }else{
                this.konfirmasiPanggilPostData()                       
            }
        },
        
        konfirmasiPanggilPostData(){
            this.rowEdit.type = 'panggil-pasien-ppa'
            this.rowEdit.ar_status = this.sPPA
            this.rowEdit.ppa = 'radiologi'

            this.loadingOverlay = true

            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: this.rowEdit}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false

                if(this.rowEdit.ar_is_called_ppa_radiologi !== 'Y'){
                    
                    if((+this.$route.query.page||1) != 1){
                        this.doResetData()
                    }else{
                        this.apiGet(['list', 'resume'])
                    }

                    this.$swal({
                        title: `Berhasil`,
                        text: 'Status Pasien Berubah Menjadi VOID',
                        icon: 'success',
                    })
                }else{
                    if(this.user.levelId !== 1){
                        this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.rowEdit.ap_id }, query: {regId: this.rowEdit.ar_id} }).catch(()=>{})
                    }else{
                        this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.rowEdit.ap_id }, query: {regId: this.rowEdit.ar_id, byPassLevel: this.user.levelId == 1 ? this.uRadiologi : null } }).catch(()=>{})
                    } 
                }
                this.openPanggil = false
            })
        },


        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },
        checkAll(e){
            if(e){
                this.selectedFilter = [
                    'ar_reg_code','ap_fullname','ar_status','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','ar_penunjang','ap_code'
                ]
            }else{
                this.selectedFilter = []
            }
        },
        approvalEdit(v){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'get-user', id: v.arm_can_edit_by}}, 
                "POST"
            ).then(res=>{
                
                let resp = res.data

                 this.$swal({
                    title: `Setujui Permintaan dari ${resp.dataGizi.bu_full_name}?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText:  `Ya!`,
                    cancelButtonText:  `Tidak, kembali`,        
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        Gen.apiRest(
                            "/do/"+this.modulePage,
                            {data:{type:'approval', id: v.arm_id, arm_is_approve: 'Y'}}, 
                            "POST"
                        ).then(()=>{
                            this.loadingOverlay = false 
                            this.$swal({
                                title: `Permintaan Berhasil Disetujui`,
                                icon: 'success',
                            })

                            if((+this.$route.query.page||1) != 1){
                                this.doResetData()
                            }else{
                                this.apiGet(['list', 'resume'])
                            }

                        }).catch(err=>{ 
                            this.loadingOverlay = false 
                            if(err){
                                err.statusType = err.status
                                err.status = "error"
                                err.message = err.response.data.message
                                err.title = err.response.data.title
                                err.messageError = err.message
                            }
                            this.doSetAlertForm(err)
                        })
                    }
                })
            })
        },
        
        redirect(v){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id, rmNo: v.arm_id,
            typeKajian:'kajian-radiologi' }, query: {regId: v.ar_id} }).catch(()=>{})
        },
        tableScrollFunction(e) {
           const responsiveTable = document.querySelector('.table-responsive')
           if(responsiveTable){
                let scrollDelta = e.deltaY
                let resScrollPos = responsiveTable.scrollTop + scrollDelta
                responsiveTable.scroll({
                    left: responsiveTable.scrollLeft,
                    top: resScrollPos,
                    behavior: 'smooth'
                })
            }
        },
        
        setSlide(){
            const responsiveTable = document.querySelector('.table-responsive')
            const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
            const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
            if(responsiveTableScroll){
                responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                Array.from(responsiveTableScrollActions).forEach(action => {
                    action.addEventListener('click', e => {
                        const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                         
                        responsiveTable.scroll({
                            left: scrollAmount,
                            behavior: 'smooth'
                        })
                    })
                })
            }
        },

        windowTableScroller(e){
            const responsiveTable = document.querySelector('.table-responsive')
            if(responsiveTable){
                                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                if(responsiveTableScroll){
                    responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                    this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                    const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                    if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                        this.onScrollPoint = true
                        document.documentElement.scroll(0, this.tableScrollPoint)
                        window.addEventListener('wheel', this.tableScrollFunction, false)
                    }
                }
            }
        },
        checkNullAction(cmp){
            return $(`#${cmp}`).is(':empty')
        },
        getConfigDynamic(master, value) {
            let text = ''
            if (value) {
            let index = (master||[]).findIndex(x => x.value == value)
            if (index !== -1) {
                text = master[index]['text']
            }
            }
            return text
        },
        
        changeJadwalRadiologi(v){
            this.rowEdit = v
            this.rowEdit.ar_reg_ppa_radiologi_new = null
            this.changeJadwal = true
        },

        changeJadwalRadiologiNew(v){
            console.log(v)
            this.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'detail-radiologi-new', id: v.ar_id, is_penunjang: v.ar_is_reg_penunjang}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false
                this.rowDetail = res.data.dataDokter[0]               
                this.dataListDokter = res.data.dataDokter              
                this.changeJadwalNew = true
                this.regId = v.ar_id
            })
        },
    },
    mounted() {
        let filterColumn = JSON.parse(localStorage.getItem(`kolumn_radiologi_${this.user.id}`) || '[]')
        if(filterColumn.length){
            this.selectedFilter = filterColumn
            this.acceptedFilter = filterColumn
        }

        this.filter.page = 1
        
        if(this.user.mpo_id){
            this.filter.poli = 'ALL'
        }

        if(this.$route.query.startDate && this.$route.query.endDate){
            this.dateRange.startDate = this.$route.query.startDate
            this.dateRange.endDate = this.$route.query.endDate
        }
        
        if(this.$route.query.page != 1){
            let url = {
                name:this.$route.name,
                params: (this.$route.params||{}),
                query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
            }
            this.$router.push(url).catch(()=>{})
        }else{
            this.apiGet(['list', 'master_dan_resume'])
        }
        
        if(this.filter.poli){
            this.filter.poli = +this.filter.poli
        }
        
        const stickyTable = document.querySelector('.sticky-table')
        console.log(stickyTable)
        if(stickyTable){
            stickyTable.addEventListener('scroll', this.handleScroll)
        }
        
        window.addEventListener('scroll', this.windowTableScroller)
        this.onScrollPoint = false
        
        setTimeout(()=>{
            if(this.isList){
                this.setSlide()
            }
        },1000)
    },  
    created () {
        // window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        // window.removeEventListener('scroll', this.handleScroll)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('scroll', this.windowTableScroller)
    },
    watch:{
        $route(vNew, vOld){
			if (
				this.firstInitLoaded.indexOf('master') > -1 && 
				this.firstInitLoaded.indexOf('resume') > -1
			) {
                if (vNew.query.page > vOld.query.page) {
				    this.apiGet(['list']) // table scroll = load list only
                } else {
                    this.apiGet(['list', 'resume'])
                }
			} else {
				this.apiGet(['list', 'master_dan_resume']) // reload all
			}
            
            setTimeout(()=>{
                if(this.isList){
                    this.setSlide()
                }
            },1000)
        },
        'onScrollPoint'(v){
            document.body.style.overflow = v ? 'hidden' : 'auto'
        },
        'openPanggil'(v){
            if(!v && Object.keys(this.rowEdit||{}).length){
                setTimeout(()=>{
                    this.rowEdit.ar_is_called_ppa_radiologi = 'N'    
                },1000)
            }
        }   
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
</style>